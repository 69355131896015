<template>
    <div>
        <b-row class="mb-3">
            <b-col cols="10">
                <h4>Account</h4>
            </b-col>
            <b-col class="ml-auto text-right">
                <b-link @click="logout()">Logout</b-link>
            </b-col>
        </b-row>

        <div v-if="accountEmail">
            <b-row>
                <b-col sm="6">
                <h5>Personal Info</h5>
            <b-card class="mb-5">
                <b-row>
                    <b-col>
                        <b-input-group prepend="Email">
                        <b-input disabled :value="accountEmail" placeholder="Enter your email"></b-input>
                        
                        <b-input-group-append>
                            <b-button size="sm" @click="changePassword()">Reset password</b-button>
                        </b-input-group-append>
                    </b-input-group>
                    </b-col>
                </b-row>
            <b-row class="mt-3">
                <b-col>
                    <b-input-group prepend="Name" class="mb-3">
                        <b-input v-model="customer.fullName" placeholder="Enter your full name" :disabled="!hasCustomer" @keyup.enter="$event.target.blur()" @blur="saveName"></b-input>
                    </b-input-group>

                    <account-phone :customer="customer" @updateAccount="getCustomer"></account-phone>
                </b-col>
            </b-row>


            </b-card>

             <b-row>
                <b-col>
                    <h5>Addresses</h5>

                    <account-address :customer="customer" @updateAccount="getCustomer"></account-address>
                </b-col>
            </b-row>
            
            </b-col>

            <b-col>
                    <h5>Orders</h5>
                    <b-card v-if="orders.length" class="table-card">
                        <b-table :items="orders" :fields="orderFields" striped>
                            <template #cell(orderId)="data">
                                <b-button size="sm" :to="'/order/'+data.value">{{ data.value }}</b-button>
                            </template>

                            <template #cell(orderTotal)="data">
                                <span>${{ data.value }}</span>
                            </template>

                            <template #cell(location)="data">
                                <span>{{ orderStatus(data.value, data.item.shipping.shipments) }}</span>
                            </template>

                            <template #cell(date)="data">
                                <span>{{ formatDate(data.item.platform.date) }}</span>
                            </template>
                        </b-table>
                    </b-card>

                    <div v-else>
                        No orders found.
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import accountApi from "../api/account";
    import AccountAddress from "../components/AccountAddress";
    import AccountPhone from "../components/AccountPhone";
    import accountMixin from "../mixins/account";
    import orderMixin from "../mixins/order";

    export default {
        name: "Account",
        components: { AccountAddress, AccountPhone },
        mixins: [ accountApi, accountMixin, orderMixin ],
        data() {
            return {
                customer: {},
                customerExists: false,
                orders: [],
                orderFields: [
                    { key: 'orderId', label: 'Order' },
                    { key: 'date', label: 'Date' },
                    { key: 'orderTotal', label: 'Total' },
                    { key: 'location', label: 'Status' },
                ],
            }
        },
         async mounted() {
             if (this.$auth.isAuthenticated) {
                this.loadAccount();
             }
        },
        methods: {
            async loadAccount() {
                try {
                    await this.getCustomer();
                    this.orders = await this.fetchAccountOrders();        
                } catch (err) {
                    if (err.response && err.response.status && err.response.status === 401) {
                        await this.logout();
                        await this.login('/account');
                    } else {
                        this.showError('Could not load your account.');
                    }
                }
            },
            async saveName() {
                    let payload = {
                        fullName: this.customer.fullName,
                    }

                    await this.updateCustomer(payload);

                    this.showToast('Name Change', 'Your name has been updated.');
            },
            async getCustomer() {
                const cust = await this.getCustomerByEmail(this.$auth.user.email);

                if (Object.keys(cust).length > 0) {
                    if (!cust.accountId && this.$auth.user.email_verified) {
                        // update customer with accountId
                    }
                    
                    this.customer = cust;
                    this.customerExists = true;
                } else {
                    await this.createAccount();
                
                    // create customer
                    // console.log(payload);
                }
            },
            async changePassword() {
                try {
                    await this.changeAccountPassword(this.$auth.user.email);

                    this.showToast('Password Reset', 'Please check your email for reset instructions.');
                } catch (err) {
                    this.showError('Could not send password reset email.');
                }
            },
            async createAccount() {
                let payload = {
                    accountId: this.$auth.claims.sub,
                    email: [ this.$auth.user.email ],
                }

                const resp = await this.createCustomer(payload);
                this.customer = resp;
                this.customerExists = true;
            },
        },
        computed: {
            accountEmail() {
                if (this.$auth && this.$auth.user && this.$auth.user.email) {
                    return this.$auth.user.email;
                }

                return '';
            },
            hasCustomer() {
                return Object.keys(this.customer).length > 0;
            }
        },
        watch: {
            '$auth.user.email': function(email) {
                if (email.length) {
                    this.loadAccount();      
                }
            }
        }
    }
</script>

<style scoped>
    .card-body {
        background: #f3f3eb !important;
    }

    .table-card  .card-body {
        padding: 0 !important;
    }

    .order-link {
        background: #f3f3eb !important;
    }
</style>