<template>
    <div>
        <b-row>
            <b-col>
                <b-form-group label="Street Address" label-for="input-address">
                    <b-form-input
                            id="input-address"
                            v-model="address.line1"
                            placeholder="Street address, PO Box, company name, c/o"
                            required
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group label="Apt #" label-for="input-address2">
                    <b-form-input
                            id="input-address2"
                            v-model="address.line2"
                            placeholder="Apartment, suite, unit, building, etc."
                            required
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group label="City" label-for="input-city">
                    <b-form-input
                            id="input-city"
                            v-model="address.city"
                            required
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <b-col>
                <b-form-group label="State / Province" label-for="input-state">
                    <b-form-input
                            id="input-state"
                            v-model="address.state"
                            required
                    ></b-form-input>
                </b-form-group>
            </b-col>

            <b-col>
                <b-form-group label="Zip / Postal Code" label-for="input-zip">
                    <b-form-input
                            id="input-zip"
                            v-model="address.zip"
                            required
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group label="Country" label-for="input-country">
                    <b-form-select v-model="address.country">
                        <b-form-select-option :value="null" disabled>Select your country</b-form-select-option>
                        <b-form-select-option v-for="(country, index) in countries" :key="index" :value="country.code">{{ country.name }}</b-form-select-option>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import countryList from "../assets/countryList";

    export default {
        mixins: [ countryList ],
        data() {
            return {
                address: {
                    line1: null,
                    line2: null,
                    city: null,
                    state: null,
                    zip: null,
                    country: null,
                }
            }
        },
        watch: {
            address: {
                deep: true,
                handler() {
                    this.$emit('updateAddress', this.address);
                }
            }
        }
    }
</script>
