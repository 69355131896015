<template>
    <div>
        <b-row class="mb-3">
            <b-col>
                <h4>Shipping Info</h4>
            </b-col>
        </b-row>
        
        <b-row>
            <b-col sm="6">
                <div v-if="$auth.isAuthenticated">    

                    <b-input-group prepend="Name" class="mb-3">
                        <b-input v-model="customer.fullName" placeholder="Enter your full name" :disabled="!hasCustomer" autocomplete="chrome-off" @blur="saveName"></b-input>
                        <!-- <b-input-group-append>
                            <b-button @click="saveName" size="sm" :disabled="!hasCustomer">Update</b-button>
                        </b-input-group-append> -->
                    </b-input-group>

                    <b-card class="mb-3" v-if="!customer.address || customer.address.length === 0" header="No addresses for this account">Please add one below</b-card>                
                    
                    <account-address :customer="customer" isSelecting="true" @addressSelected="addressSelected"></account-address>
                </div>

                <!-- <div v-else>
                    <address-form @updateAddress="handleAddressUpdate"></address-form>
                </div> -->
            </b-col>

            <b-col sm="6">
                <b-form-group label="Shipping Method" label-for="input-ship-method">
                    <b-form-select v-model="selectedOption" @change="changeShippingMethod" :disabled="!isMethodAvailable" id="input-ship-method">
                            <b-form-select-option :value="null">Select shipping method</b-form-select-option>
                            <b-form-select-option v-for="(option, index) in shippingOptions"
                                                    :value="option.name"
                                                    :key="index">{{ option.name }} - ${{ option.cost }}</b-form-select-option>
                    </b-form-select>
                </b-form-group>    

                <b-button :disabled="!isMethodAvailable || !this.$store.state.shippingMethod" size="sm" to="/checkout/payment" variant="danger">Proceed to payment</b-button>        
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import accountApi from "../api/account";
    import AccountAddress from "./AccountAddress";
    // import AddressForm from "./AddressForm";
    import shippingMixin from "../mixins/shipping";
    import cartMixin from "../mixins/cart";

    export default {
        name: "Shipping",
        mixins: [ accountApi, shippingMixin, cartMixin ],
        components: { AccountAddress },
        data() {
            return {
                customer: {},
                address: {},
                shippingOptions: [],
                selectedOption: null,
            }
        },
        async mounted() {
            if (this.$auth.isAuthenticated) {
                await this.fetchCustomer();

                if (this.countryCode.length) {
                    this.address.country = this.countryCode;
                    
                    await this.buildShippingOptions();
                    
                    if (this.shippingOptions.length === 1) {
                        this.selectedOption = this.shippingOptions[0].value;
                        this.changeShippingMethod();
                    }                
                }
            }
        },
        methods: {
            async saveName() {
                    let payload = {
                        fullName: this.customer.fullName,
                    }

                    await this.updateCustomer(payload);

                    this.showToast('Name Change', 'Your name has been updated.');
            },
            addressSelected(address) {
                this.handleAddressUpdate(address);
            },
            async handleAddressUpdate(address) {
                this.address = address;

                this.$store.commit('storeAddress', address);
                this.$store.commit('storeCountryCode', address.country);

                this.shippingOptions = [];
                await this.buildShippingOptions();
                
                if (this.shippingOptions.length === 1) {
                    this.selectedOption = this.shippingOptions[0].value;
                    this.changeShippingMethod();
                }
            },
            changeShippingMethod() {
                this.$store.commit('changeShipping', this.selectedOption);
            },
            async fetchCustomer() {
                try {
                    const cust = await this.getCustomerByEmail(this.$auth.user.email);
                    
                    if (cust) {
                        this.customer = cust;
                    }
                } catch (err) {
                    if (err.response && err.response.status && err.response.status === 401) {
                        await this.logout();
                        await this.login(this.$route.path);
                    } else {
                        this.showError('Could not load your account information.');
                    }
                }
            }
        },
        computed: {
            countryCode() {
                return this.$store.state.countryCode;
            },
            isDomestic() {
                return this.address && (this.address.country === 'US' || this.address.country === 'United States');
            },
            isMethodAvailable() {
                return this.address && this.address.country && this.address.country.length > 0 && this.customer.fullName && this.customer.fullName.length > 0;
            },
            hasCustomer() {
                return Object.keys(this.customer).length > 0;
            }
        },
        watch: {
            '$auth.user.email': function(email) {
                if (email) {
                    this.fetchCustomer();
                }
            },
            shippingOptions() {
                if (this.$store.state.shippingMethod && this.$store.state.shippingMethod.length) {
                    this.selectedOption = this.$store.state.shippingMethod;
                }
            }
        }
    }
</script>
