<template>
    <div>
        <!-- <b-nav pills class="mb-5">
            <b-nav-item to="/cart">Cart</b-nav-item>
            <b-nav-item to="/checkout/shipping" exact exact-active-class="active">Shipping Info</b-nav-item>
            <b-nav-item to="/checkout/payment" exact exact-active-class="active">Payment</b-nav-item>
        </b-nav> -->

        <router-view></router-view>
    </div>
</template>

<script>
    import accountApi from "../api/account";

    export default {
        name: "Checkout",
        mixins: [ accountApi ],
        data() {
            return {
                customer: {},
            }
        },
        async mounted() {
            if (this.$auth.isAuthenticated) {
                const cust = await this.getCustomerByEmail(this.$auth.user.email);
                
                if (cust) {
                    this.customer = cust;
                }
            }
        }
    }
</script>

<style scoped>

</style>