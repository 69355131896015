<template>
    <div>
        <h4 class="mb-3">Sell Your Collection</h4>

        <b-card>
            <p class="spacing">We are in constant need of records and will travel anywhere given sufficient quantity and quality.</p>
            <p class="spacing">Because we often travel to buy records in person, we're generally interested in collections of 1,000 pieces or larger. Exceptions do apply, particularly if you have a smaller number of rarer records.</p>
            <p class="spacing">We require top condition, interesting titles in most genres. If you have a collection you'd like to sell, let us know the quantity, musical categories (most types of pre'85 music are of interest), source (&#8220;My relative was in the record business in 1958/68, etc&#8221; is always a good start!), and where you are located.</p>
            <p class="spacing">We DON'T need 78s or very common LPs and 45s (the biggest hits of their era) in average condition.</p>
            <p class="spacing">If you have a collection that you think we may be interested in, please contact us.</p>
        </b-card>

        <contact class="mt-3"></contact>
    </div>
</template>
<script>
import Contact from "./Contact.vue";

export default {
    components: { Contact },
}
</script>

<style scoped>
    .card-body {
        background: #f3f3eb !important;
    }
</style>