import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home';
import Browse from '../views/Browse';
import Cart from '../views/Cart';
import Checkout from '../views/Checkout';
import Shipping from '../components/Shipping';
import Payment from '../components/Payment';
import OrderPayment from '../components/OrderPayment';
import Order from '../components/Order';
import Account from '../views/Account';
import ItemView from "../views/ItemView";
import Wants from "../views/Wants";
import Search from "../views/Search";
import Help from "../views/Help.vue";
import About from "../views/help/About";
import Grading from "../views/help/Grading";
import ShippingReturns from "../views/help/ShippingReturns";
import Contact from "../views/help/Contact";
import Collection from "../views/help/Collection";
import Privacy from "../views/help/Privacy";
import Terms from "../views/help/Terms";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: { title: 'Records By Mail' },
        component: Home,
    },
    {
        path: '/browse',
        name: 'Browse',
        meta: { title: 'Browse | Records By Mail' },
        component: Browse,
        force: true,
    },
    {
        path: '/account',
        name: 'Account',
        meta: { title: 'Account | Records By Mail' },
        component: Account,
    },
    {
        path: '/cart',
        name: 'Cart',
        meta: { title: 'Cart | Records By Mail' },
        component: Cart,
    },
    {
        path: '/checkout',
        name: 'Checkout',
        meta: { title: 'Checkout | Records By Mail' },
        component: Checkout,
        children: [
            { path: 'shipping', component: Shipping, meta: { title: 'Shipping | Records By Mail' } },
            { path: 'payment', component: Payment, meta: { title: 'Payment | Records By Mail' } },
        ]
    },
    {
        path: '/payment/:orderId?',
        name: 'Payment',
        meta: { title: 'Payment | Records By Mail' },
        component: OrderPayment,
    },
    {
        path: '/order/:orderId',
        name: 'Order',
        meta: { title: 'Order | Records By Mail' },
        component: Order,
    },
    {
        path: '/item/:itemId',
        name: 'Item',
        meta: { title: 'Item | Records By Mail' },
        component: ItemView,
    },
    {
        path: '/wants',
        name: 'Wants',
        meta: { title: 'Wants | Records By Mail' },
        component: Wants,
    },
    {
        path: '/search/:query',
        name: 'Search',
        meta: { title: 'Search | Records By Mail' },
        component: Search,
    },
    {
        path: '/help',
        name: 'Help',
        meta: { title: 'Help | Records By Mail' },
        component: Help,
        children: [
            { path: 'about', component: About, meta: { title: 'About Us | Records By Mail' } },
            { path: 'grading', component: Grading, meta: { title: 'Record Grading | Records By Mail' } },
            { path: 'shipping', component: ShippingReturns, meta: { title: 'Shipping & Returns | Records By Mail' } },
            { path: 'contact', component: Contact, meta: { title: 'Contact Us | Records By Mail' } },
            { path: 'privacy', component: Privacy, meta: { title: 'Privacy Notice | Records By Mail' } },
            { path: 'terms', component: Terms, meta: { title: 'Terms Of Use | Records By Mail' } },
            { path: 'collection', component: Collection, meta: { title: 'Sell Your Collection | Records By Mail' } },
        ]
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
    // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router
