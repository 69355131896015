import apiClient from "../services/apiClient";

export default {
    methods: {
        async handlePaymentFromPayPal() {
            if (this.$route.query.cancel) {
                this.selected = 'PayPal';
                this.showToast('No payment was made', 'You cancelled the payment.');
            } else if (this.$route.query.PayerID) {
                try {
                    this.selected = 'PayPal';

                    let transactionId = this.$route.query.token;
                    // successful paypal payment, now capture and create the order
                    this.isCreating = true;
                    let date = Date.now();
                    const resp = await apiClient.post(`${process.env.VUE_APP_ORDERS_API_URL}/payment/paypal/capture/${transactionId}`, { orderIds: this.orderIds, payment: { date: date, method: 'PayPal', transactionId: transactionId, amount: this.totals.total } });

                    if (resp.data.status && resp.data.status === 'COMPLETED') {
                        this.hasPaid = true;
                    } else {
                        this.showError('Your payment was not processed.');
                    }
                } catch (err) {
                    this.showError('Your payment was not processed.');
                } finally {
                    this.isCreating = false;
                }
            }
        },
        async handleReturnFromPayPal() {
            if (this.$route.query.cancel) {
                this.selected = 'PayPal';
                this.showToast('No payment was made', 'You cancelled the payment.');
            } else if (this.$route.query.PayerID) {
                let transactionId = this.$route.query.token;

                try {
                    this.selected = 'PayPal';
                    this.isCreating = true;
                                        
                    await this.doOrderCreate('PayPal', transactionId);

                    await apiClient.post(`${process.env.VUE_APP_ORDERS_API_URL}/payment/paypal/capture/${transactionId}`);
                } catch (err) {
                    this.showError('There was a problem creating your order.');
                }
            }
        }
    }
}