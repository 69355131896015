import accountApi from '../api/account';

export default {
    mixins: [ accountApi ],
    methods: {
        async login(redirectPage) {  
          await this.$auth.loginWithRedirect( { redirect_uri: `${window.location.origin}${redirectPage}?loggedIn=1` });
        },
        async logout() {
          this.$store.commit('storeWants', []);
          this.$store.commit('logout');

          await this.$auth.logout({
            returnTo: window.location.origin
          });
        },
        async handleLogin() {
          if (this.$auth && this.$auth.isAuthenticated && this.$auth.user) {

            this.$store.commit('login');
            // see if account is linked to auth0
            try {
              await this.getCustomerByEmail(this.$auth.user.email);
            } catch (err) {
              if (err.response && err.response.status && err.response.status === 404) {
                let payload = {
                  accountId: this.$auth.claims.sub,
                  email: [ this.$auth.user.email ],
                }
                await this.createCustomer(payload);

              } else {
                this.showError('There was a problem with your account.');
              }
            }

            const wantResp = await this.getWants(this.$auth.user.email);
            let wants = wantResp.itemIds || [];
            this.$store.commit('storeWants', wants);
  
            const cartResp = await this.getCart(this.$auth.user.email);

            let cartItems = cartResp.items || [];
            
            cartItems.push(...this.$store.state.cart);
  
            this.$store.commit('storeCart', cartItems);
          }
        },
    }
}