<template>
    <div>
        <b-card-group deck class="row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-xl-5">
            <div v-for="item in items"
                 :key="item._id" @click="viewItem(item)">
                <!-- <b-card
                        footer-tag="footer"
                        :img-src="buildImgUrl(item)"
                        img-top
                        class="card-item"> -->
                    <b-card
                        footer-tag="footer"
                        class="card-item">
                <b-overlay :show="!inStock(item)" opacity="0.5">

                    <b-card-img top v-if="hasImage(item) && inStock(item)" :src="buildImgUrl(item)" class="overflow-hidden"></b-card-img>
                    <div v-else class="generic-image-holder">
                      <b-aspect class='align-items-center'>
                        <b-card-img top :src="genericImage" class="blank-image-container"></b-card-img>
                      </b-aspect>
                    </div>

                  <template #overlay>
                    <small class='text-success'>NO STOCK</small>
                </template>
                </b-overlay>
                
                    <b-card-text>
                        {{ buildArtist(item) }}
                        <br/>
                        {{ buildTitle(item) }}
                        <br/>
                        <span class="text-success" v-if="inStock(item)">${{ item.listing.price.toLocaleString() }}</span>
                        
                    </b-card-text>
                    <div v-if="wantView" class="mt-3">
                        <b-button @click.stop="doRemoveFromWants(item.itemId)">Remove</b-button>
                    </div>
                </b-card>

              
            </div>
        </b-card-group>
        <b-modal id="itemModal"
                 size="lg"
                 scrollable
                 hide-header
                 button-size="sm"
                 no-stacking
                 ok-only 
                 centered
                 @hide="closeModal"
                 @close="closeModal">
            <item :item="selectedItem"></item>
        </b-modal>

        <b-modal id="cartModal"
                 size="md"
                 hide-header
                 button-size="lg"
                 centered>
            Item was added to your cart.

            <template #modal-footer>
                <b-button size="sm" variant="secondary" @click="$router.push('/cart')">
                    View Cart
                </b-button>
                <b-button size="sm" variant="primary" @click="$bvModal.hide('cartModal')">
                    Keep Shopping
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import Item from "./Item";
    import itemMixin from "../mixins/item";
    import wantApi from "../api/want";

    export default {
        name: "NewArrivals",
        components: { Item },
        mixins: [ itemMixin, wantApi ],
        props: [ 'items', 'wantView' ],
        data() {
            return {
                selectedItem: {},
            }
        },
        methods: {
            handleParams() {
                if (this.$route.query && this.$route.query.item) {
                    let item = this.items.find(i => i.itemId === parseInt(this.$route.query.item));                
                
                    if (item) {
                        this.viewItem(item);
                    }
                }
            },
            hasImage(item) {
                return item.listing.images && item.listing.images[0];
            },
            buildImgUrl(item) {
                if (!item.listing.images || !item.listing.images[0]) {
                    return `${process.env.VUE_APP_ASSETS_URL}/rbm_logo.png`;
                    // return;
                }

                const imageRequest = JSON.stringify({
                    bucket: 'record-photos',
                    key: item.listing.images[0].url,
                    edits: { resize: { width: 350 } },
                });

                return `${process.env.VUE_APP_ITEM_IMAGE_PATH}/${btoa(imageRequest)}`;
            },
            viewItem(item) {
                // window.history.replaceState(null, null, '?item=' + item.itemId);

                this.selectedItem = item;
                this.$bvModal.show('itemModal');
            },
            closeModal() {
                this.$emit('reload');
                // window.history.replaceState(null, null, '/');
            },
            async doRemoveFromWants(itemId) {
                const resp = await this.deleteWant(this.$auth.user.email, itemId);
                this.$store.commit('storeWants', resp.itemIds);
                this.$emit('reload');
            }
        },
        computed: {
            genericImage() {
                return `${process.env.VUE_APP_ASSETS_URL}/rbm_logo.png`;
            },
        },
        watch: {
            items() {
                if (this.items.length === 1 && this.$route.name !== 'Wants') {
                    this.viewItem(this.items[0]);
                }
            }
        }
    }
</script>

<style scoped>
    .card-body {
        background: #f3f3eb !important;
        padding-top: 0;
    }
    
    .card-img-top {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background: #f3f3eb;
    }

    .card-item:hover {
        cursor: pointer;
    }

    /* .card-img {
        width: 213px;
        height: 213px;
    } */

    .card-text {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 72px;
    }

    .card-deck .card {
      margin-bottom: 15px;
    }

    .generic-image-holder {
      padding: 16px 0;
    }

    /* .blank-image-container {
        opacity: 0.5;
    } */

    @media (max-width: 600px) {
    .card-img {
        width: 100%;
        height: auto;
    }
    }
</style>

<style>



  footer {
        display: none !important;
    }

    @media (max-width: 600px) {
        footer {
            display: block !important;
        }
    }
</style>