<template>
    <div>
        <b-card no-body class="overflow-hidden">
            <b-row no-gutters>
                <b-col lg="4" md="6" class="p-3 img-container">
                    <div>
                        <div v-if="buildImgUrl().length"> 
                            <b-card-img :src="buildImgUrl()" alt="Image" class="rounded-0"></b-card-img>
                        </div>
                        <div v-else class="blank-image-container text-center">
                            <img src="../assets/rbm_logo.png" width="213" />
                        </div>
                    </div>
                </b-col>

                <b-col lg="8" md="6">
                    <b-card-body>
                        <b-card-text>
                            <small>Item #{{ item.itemId }} &middot; {{ buildGenre(item) }}</small>
                            <h4>{{ buildArtist(item) }}</h4>
                            <h5>{{ buildFullTitle(item) }}</h5>
                            <p class="mb-0">{{ buildLabelName(item) }} {{ buildLabelNo(item) }}</p>
                            <div v-if="hasLabelDetails">
                                <p class="mb-0"><small v-if="hasDeadwax">Deadwax: {{ item.release.label.deadwax }}</small></p>
                                <p class="mb-0"><small v-if="hasAddress">Address: {{ item.release.label.address }}</small></p>
                                <p class="mb-0"><small v-if="hasLocale">Locale: {{ item.release.label.locale }}</small></p>
                            </div>

                            <!-- <p class="mb-0">Genre: <span>{{ buildGenre(item) }}</span>
                                <span v-if="buildStyles(item).length">, {{ buildStyles(item) }}</span>
                            </p> -->
                            <p class="mb-0" v-if="buildStyles(item).length">Style: {{ buildStyles(item) }}</p>

                            <p>Format: {{ buildMedia(item) }}</p>

                            <div v-if="buildPressing(item).length" class="my-3">
                                <!-- <p class="my-0" v-for="(pressing, index) in buildPressing(item)" :key="index">
                                    {{ capitalize(pressing.type) }}: {{ pressing.text }}
                                </p> -->
                                <p class="my-0">
                                    Pressing: {{ buildPressing(item) }}
                                </p>
                            </div>

                            <p v-for="(grade, index) in buildCondition(item)" :key="index">
                                {{ grade }}
                            </p>

                            <em>{{ buildAttributes(item) }}</em>
                            <h5 class="text-success mt-3">${{ item.listing.price }}</h5>
                            
                            <div v-if="!isReadOnly" class="mt-3">
                                <b-button v-if="!inCart(item)" variant="primary" @click="addToCart(item)">Add to cart</b-button>
                                <b-button v-else variant="danger" @click="removeFromCart(item)">Remove from cart</b-button>
                            </div>

                            <!-- <div v-else>
                                <p>Quantity: {{ item.quantity }}</p>
                            </div> -->
                        </b-card-text>
                    </b-card-body>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
    import itemMixin from "../mixins/item";
    import cartMixin from "../mixins/cart";

    export default {
        name: "CartItem",
        props: [ 'item', 'isReadOnly' ],
        mixins: [ itemMixin, cartMixin ],
        methods: {
            buildImgUrl() {
                if (!this.item.listing.images || !this.item.listing.images[0]) {
                    return ``;
                }

                const imageRequest = JSON.stringify({
                    bucket: 'record-photos',
                    key: this.item.listing.images[0].url,
                    edits: { resize: { width: 300 } },
                });

                return `${process.env.VUE_APP_ITEM_IMAGE_PATH}/${btoa(imageRequest)}`;
            },
        },
        computed: {
            hasLabelDetails() {
                return this.item.label && (this.item.label.deadwax || this.item.label.address || this.item.label.locale);
            },
            hasDeadwax() {
                return this.item.label && this.item.label.deadwax;
            },
            hasAddress() {
                return this.item.label && this.item.label.address;
            },
            hasLocale() {
                return this.item.label && this.item.label.locale;
            }
        }
    }
</script>

<style scoped>
    .card {
        background: #f3f3eb !important;
    }
</style>