<template>
    <div>
        <div v-if="orderIds">
            <h4 class="mb-3">Payment</h4>

            <div v-if="hasPaid">
                Thank you for your payment!
            </div>
            <div v-else>
                <b-overlay :show="isCreating" opacity="0.5">
                    <b-row>
                        <b-col sm="12" md="6" class="mb-3">
                            <h5>Select payment method</h5>

                            <b-card>
                                <b-form-group :disabled="totals.total < 1" class="mb-0">
                                    <div class="paypal-button"></div>
                                    <b-form-radio-group
                                            class="payment-options"
                                            v-model="selected"
                                            :options="options"
                                            name="plain-inline"
                                            plain
                                    ></b-form-radio-group>
                                </b-form-group>

                                <stripe class="mt-3" v-if="selected === 'Credit Card'" @paymentMade="handlePaymentCC('Credit Card', $event)" :amount="totals.total"></stripe>
                            </b-card>
                        </b-col>

                        <b-col md="3">
                            <h5>Order<span v-if="orderIds.length > 1">s</span> {{ readableOrderIds }} Details</h5>
                            <b-list-group>
                                <b-list-group-item>Item Total: ${{ totals.item }}</b-list-group-item>
                                <b-list-group-item>Shipping: ${{ totals.shipping }}</b-list-group-item>
                                <b-list-group-item v-if="totals.discounts">Discounts: (${{ totals.discounts }})</b-list-group-item>
                                <b-list-group-item>Order Total: ${{ totals.total }}</b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>
                </b-overlay>
            </div>
        </div>

        <div v-else>
            <h4 class="mb-3">Payment</h4>
            
            <div>No order was found.</div>
        </div>
    </div>
</template>

<script>
    import Stripe from "./Stripe";
    import accountApi from "../api/account";
    import orderMixin from "../mixins/order";
    import shippingMixin from "../mixins/shipping";
    import orderApi from "../api/order";
    import paymentMixin from "../mixins/payment";

    export default {
        name: "Payment",
        components: { Stripe },
        mixins: [ accountApi, orderMixin, shippingMixin, orderApi, paymentMixin ],
        data() {
            return {
                orderIds: null,
                orders: [],
                totals: {},
                selected: '',
                options: [
                    { text: 'Credit Card', value: 'Credit Card' },
                ],
                isCreating: false,
                shippingOptions: [],
                hasPaid: false,
            }
        },
        async created() {
            if (this.$route.params.orderId) {
                this.orderIds = this.$route.params.orderId.split(',');
            }
        },
        async mounted() {
            await this.buildShippingOptions();

            this.initPayPal();
        },
        methods: {
            initPayPal() {  
                window.paypal.Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                description: this.orderIdString,
                                amount: {
                                    currency_code: "USD",
                                    value: this.totals.total,
                                    breakdown: {
                                        item_total: {
                                            currency_code: "USD",
                                            value: this.totals.item,
                                        },
                                        shipping: {
                                            currency_code: "USD",
                                            value: this.totals.shipping,
                                        },
                                        discount: {
                                            currency_code: "USD",
                                            value: this.totals.discounts,
                                        }
                                    }
                                }
                            }]
                        });
                    },
                    onApprove: async (data, actions) => {
                        this.isCreating = true;
                        const order = await actions.order.capture();
                        await this.handlePayment('PayPal', order.id);
                        console.log(order);
                    },
                    onError: err => {
                        this.showError('Something went wrong with your payment');
                        console.log(err);
                    },
                    style: {
                        layout:  'horizontal',
                        color:   'gold',
                        shape:   'pill',
                        label:   'paypal',
                        tagline: false,
                    }
                }).render('.paypal-button');
            },
            async handlePayment(paymentType, transactionId) {
                this.isCreating = true;
                try {
                    let date = Date.now();
                    await this.markOrdersPaid({ orderIds: this.orderIds, payment: { date: date, method: paymentType, transactionId: transactionId, amount: this.totals.total }});
                    this.isCreating = false;
                    this.hasPaid = true;
                } catch {
                    this.isCreating = false;
                    this.showError('Error processing payment!', 'Please contact us.');
                } 
            },

            async handlePaymentCC(paymentType, transaction) {
                this.isCreating = true;
                let transactionId = transaction.paymentIntent.id;
                
                try {
                    let date = Date.now();
                    await this.markOrdersPaid({ orderIds: this.orderIds, payment: { date: date, method: paymentType, transactionId: transactionId, amount: this.totals.total }});
                    this.isCreating = false;
                    this.hasPaid = true;
                } catch {
                    this.isCreating = false;
                    this.showError('Error processing payment!', 'Please contact us.');
                } 
            },

        },
        computed: {
            orderIdString() {
                let str = 'Order';
                if (this.orderIds.length > 1) {
                    str += 's';
                }

                str += ' ' + this.readableOrderIds;

                return str;
            },
            readableOrderIds() {
                return this.orderIds.join(', ');
            },
            paypalItems() {
                return this.cartItems.map(item => {
                    let name = `${this.buildArtist(item)}: ${this.buildTitle(item)} ${this.buildMedia(item)}`.substring(0, 127);

                    return { name: name, unit_amount: { currency_code: "USD", value: item.listing.price }, quantity: 1 }
                });
            }
        },
        watch: {
            async orderIds() {
                if (this.orderIds.length) {
                    const totals = await this.getOrderTotals(this.orderIds.join(','));
                    
                    if (totals.total < 1) {
                        this.hasPaid = true;
                    }

                    this.totals = totals;
                }
            }
        }
    }
</script>

<style scoped>
    .list-group-item {
        background: #f3f3eb !important;
    }

    .card-body {
        background: #f3f3eb !important;
    }

    .paypal-button {
        display: inline-block;
        width: 200px;
        padding-top: 6px;
    }

    .payment-options {
        display: inline-block;
        vertical-align: top;
        position: relative;
        top: 12px;
        left: 20px;
    }
</style>