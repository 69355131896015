import apiClient from "@/services/apiClient";
import axios from "axios";

export default {
    methods: {
        async getShippingOptions(items) {
            const { data } = await axios.post(`${process.env.VUE_APP_ORDERS_API_URL}/shipping/totals`, { items: items });

            return data;
        },
        async createOrder(payload) {
            const { data } = await apiClient.post(`${process.env.VUE_APP_ORDERS_API_URL}`, payload);
        
            return data;
        },
        async createOrderItem(orderId, orderItem) {
            const { data } = await apiClient.post(`${process.env.VUE_APP_ORDERS_API_URL}/${orderId}/item`, orderItem);

            return data;
        },
        async fetchOrder(orderId) {
            const { data } = await apiClient.get(`${process.env.VUE_APP_ORDERS_API_URL}/${orderId}`);
        
            return data;
        },
        async sendEmailConfirmation(orderId) {
            const { data } = await apiClient.get(`${process.env.VUE_APP_ORDERS_API_URL}/email/confirm/${orderId}`);

            return data;
        },
        async getOrderTotals(orderId) {
            const { data } = await axios.get(`${process.env.VUE_APP_ORDERS_API_URL}/totals/${orderId}`);

            return data;
        },
        async markOrdersPaid(payload) {
            const { data } = await axios.post(`${process.env.VUE_APP_ORDERS_API_URL}/paid`, payload);

            return data;
        } 
    }
}