export default {
    data() {
        return {
            recents: [
                { value: '1', text: 'Within Last Day'},
                { value: '7', text: 'Within Last Week'},
                { value: '31', text: 'Within Last Month'},
                { value: '180', text: 'Within Last 6 Months'},
            ],
            grades: [
                { value: 'SS', text: 'Still Sealed' },
                { value: 'M-', text: 'M-' },
                { value: 'VG+', text: 'VG+' },
                { value: 'VG', text: 'VG' },
                { value: 'VG-', text: 'VG-' },
                { value: 'G+', text: 'G+' },
                { value: 'G', text: 'G' },
            ],
            sizes: [
                { value: '7', text: '7"' },
                { value: '10', text: '10"' },
                { value: '12', text: '12"' },
            ],
            durations: [
                'LP',
                'Single',
                'EP',
                'CD',
                'CD Single',
                'Cassette',
                'Cassette Single',
            ],
            speeds: [
                { value: '33', text: '33 rpm' },
                { value: '45', text: '45 rpm' },
                { value: '78', text: '78 rpm' },
            ],
            priceUnders: [
                { value: '6', text: 'Under $6' },
                { value: '10', text: 'Under $10' },
                { value: '20', text: 'Under $20' },
                { value: '50', text: 'Under $50' },
                { value: '100', text: 'Under $100' },
            ],
            priceOvers: [
                { value: '10', text: 'Over $10' },
                { value: '20', text: 'Over $20' },
                { value: '50', text: 'Over $50' },
                { value: '100', text: 'Over $100' },
            ],
            genres: [ 
                'African', 
                'Black Gospel',
                'Bluegrass',
                'Blues & R&B',
                'Brazilian',
                'Christmas',
                'Classical',
                'Comedy',
                'Country',
                'Easy Listening',
                'Folk',
                'Funk',
                'International',
                'Jazz',
                'Latin',
                'Metal',
                'New Age',
                'Oldies',
                'Polka',
                'Punk/New Wave',
                'Rap/Hip-Hop',
                'Reggae',
                'Religious',
                'Rock & Pop',
                'Rockabilly',
                'Soul',
                'Soundtrack & Cast',
                'Southern Gospel',
                'Special Interest',
                'Spoken Word',
                'Vocal Groups',
                'Vocalists',
                 ],
        }
    }
}