export default {
    data() {
        return {
            component: {},
            isEditing: false,
            obj: {},
            isClone: false,
        }
    },
    methods: {
        clone(obj) {
            this.obj = obj;
            const component = Object.assign({}, obj);
            this.component = component;
        },
        edit() {
            this.isEditing = true;
        },
        save() {
            this.$emit('save', this.component);
            this.isEditing = false;
        },
        cancel() {
            this.clone(this.obj);
            this.isEditing = false;
        }

    },
    watch: {
        component: {
            deep: true,
            handler(newValue, oldValue) {
                // console.log('updated', this.component);

                if (Object.keys(oldValue).length) {
                    this.$emit('update', this.component);
                }
            }
        }
    }
}