<template>
    <div>
        <b-row class="mb-3">
            <b-col>
                <h4>Latest Arrivals</h4>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <list :items="items"
                        v-infinite-scroll="fetchMoreItems"
                        infinite-scroll-disabled="doneLoading"></list>
            </b-col>
        </b-row>

        <h5 v-if="!isLoading && items.length === 0">No items found.</h5>
        
        <div class="text-center" v-if="isLoading">
            <b-spinner label="Spinning"></b-spinner>
        </div>

        <router-view :key="$route.fullPath">
        </router-view>
    </div>
</template>

<script>
    import List from '../components/List'
    import axios from "axios";
    
    export default {
        name: "Home",
        components: {
            List,
        },
        data() {
            return {
                items: [],
                skip: 0,
                limit: 10,
                isLoading: false,
                doneLoading: false,
            }
        },
        created() {
            this.fetchNewArrivals();
        },
        methods: {
            async fetchNewArrivals() {
                try {
                    const {data} = await axios.get(process.env.VUE_APP_INVENTORY_API_URL + `?isActive=1&onsite=1&hasImages=1&recent=300&limit=${this.limit}&skip=${this.skip}&sort=added&dir=desc&nosturtz=1`);

                    this.items = data.docs;
                } catch (err) {
                    this.showError();
                    this.isLoading = false;
                }
            },
            async fetchMoreItems() {
                if (!this.doneLoading) {
                    this.skip += this.limit;
                    this.isLoading = true;

                    const {data} = await axios.get(process.env.VUE_APP_INVENTORY_API_URL + `?isActive=1&onsite=1&hasImages&recent=300&limit=${this.limit}&skip=${this.skip}&sort=added&dir=desc&nosturtz=1`);

                    if (data.docs.length === 0) {
                        this.doneLoading = true;
                    } else {
                        this.items = [...this.items, ...data.docs];
                    }

                    this.isLoading = false;
                }
            },
            async doSearch(query) {
                const { data } = await axios.get(process.env.VUE_APP_INVENTORY_API_URL + `?q=${query}&limit=50&sort=added&dir=desc&nosturtz=1`);

                this.items = data.docs;
            }
        }
    }
</script>

<style scoped>

</style>