import cartApi from "../api/cart";

export default {
    mixins: [ cartApi ],
    methods: {
        inCart(item) {
            let inCart = false;

            this.$store.state.cart.forEach(cartItem => {
                if (cartItem.listing._id === item.listing._id) {
                    inCart = true;
                }
            });

            return inCart;
        },
        async removeFromCart(item) {
            try {
                if (this.$auth.isAuthenticated) {
                    await this.deleteCartItem(this.$auth.user.email, item.itemId, item.listing._id);
                }

                this.$store.commit('removeFromCart', item);
            } catch (err) {
                this.showError('Could not remove from cart.');
            }
        },
        async addToCart(item) {
            try {
                item.cartQuantity = 1;
                
                if (this.$auth.isAuthenticated) {
                    await this.createCartItem(this.$auth.user.email, item);
                }
                
                this.$store.commit('addToCart', item);
            } catch (err) {
                this.showError('Could not add to cart.');
            }
        },
    },
    computed: {
        cartItems() {
            return this.$store.state.cart;
        },
        itemTotal() {
            let total = 0;

            this.cartItems.forEach(item => {
                if (!isNaN(item.listing.price)) {
                    total += parseFloat(item.listing.price * item.cartQuantity);
                }
            });

            return total;
        },
        shippingTotal() {
            let total = 0;

            this.shippingOptions.forEach(option => {
                if (option.name === this.selectedOption) {
                    total = option.cost;
                }
            });

            return total;
        },
        cartTotal() {
            if (this.hasFreeShipping) {
                return this.itemTotal;
            } else {
                return this.itemTotal + this.shippingTotal;
            }
        },
        shippingMethod() {
            return this.$store.state.shippingMethod;
        },
        countryCode() {
            return this.$store.state.countryCode;
        },
        isDomestic() {
            return this.countryCode === 'US';
        },
        hasFreeShipping() {
            return this.$store.state.shippingMethod === 'Media Mail' && this.isDomestic && this.itemTotal > parseInt(process.env.VUE_APP_FREE_SHIPPING_THRESHOLD);
        }
    }
}