<template>
    <div>
        <b-row>
            <b-col md="9" lg="10">
                <h4>Browse</h4>
            </b-col>

             <b-col class="ml-auto">
                    <b-form-select v-model="sort">
                    <template #first>
                        <b-form-select-option value="">Sort</b-form-select-option>
                    </template>
                    <b-form-select-option value="artist">Artist</b-form-select-option>
                    <b-form-select-option value="title">Title</b-form-select-option>
                    <b-form-select-option value="label">Label</b-form-select-option>
                    <b-form-select-option value="added">Recently Added</b-form-select-option>
                    <b-form-select-option value="highest">Highest Price</b-form-select-option>
                    <b-form-select-option value="lowest">Lowest Price</b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>

        <search-filter-list @changeFilter="handleChangeFilter($event)" @changeSort="handleChangeSort($event)"></search-filter-list>

        <b-row class="mt-3">
            <b-col>
                <list :items="items"
                           v-infinite-scroll="fetchMoreItems"
                           infinite-scroll-disabled="doneLoading"></list>
            </b-col>
        </b-row>

        <div v-if="items && items.length === 0 && !isLoading">
            No items found.
        </div>

        <router-view :key="$route.fullPath">
        </router-view>
        
        <div class="text-center" v-if="isLoading">
            <b-spinner label="Spinning"></b-spinner>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import List from '../components/List'
    import SearchFilterList from '../components/SearchFilterList.vue';

    export default {
        name: "Browse",
        components: {
            List,
            SearchFilterList,
        },
        data() {
            return {
                items: [],
                skip: 0,
                limit: 10,
                isLoading: false,
                doneLoading: false,
                search: {},
                sort: 'added',
                sortDir: 'desc',
                initialLoad: false,
            }
        },
        mounted() {
            this.handleChangeFilter({ isActive: 1 });
            //this.$root.$emit('bv::toggle::collapse', 'navbar-toggle-collapse')
        },
        methods: {
            async handleChangeFilter(search) {
                this.items = [];
                this.skip = 0;
                this.search = search;
                this.doneLoading = false;
                this.initialLoad = true;
                await this.doSearch();
            },
            handleChangeSort(sort) {
                this.sort = sort;
            },
            async fetchMoreItems() {
                if (!this.doneLoading) {
                    if (this.initialLoad) {
                        this.skip += this.limit;
                    } else {
                        this.initialLoad = true;
                    }                    
                    
                    this.isLoading = true;

                    await this.doSearch();

                    this.isLoading = false;
                }
            },
            buildQuery () {
                let query = new URLSearchParams();

                for (let i in this.search) {
                    if (this.search[i].length) {
                        query.append(i, this.search[i]);
                    } else if (this.search[i]) {
                        query.append(i, 1);
                    }
                }

                return query.toString();
            },
            async doSearch() {
                try {
                    this.isLoading = true;

                    if (this.$route.params.query) {
                        this.search.q = this.$route.params.query;
                    }

                    let query = this.buildQuery();

                    if (query.length) {
                        let sortField;

                        if (this.sort === 'highest') {
                            sortField = 'activePrice';
                            this.sortDir = 'desc';
                        } else if (this.sort === 'lowest') {
                            sortField = 'activePrice';
                            this.sortDir = 'asc';
                        } else {
                            sortField = this.sort;
                        }

                        const { data } = await axios.get(process.env.VUE_APP_INVENTORY_API_URL + `?${query}&limit=${this.limit}&skip=${this.skip}&sort=${sortField}&dir=${this.sortDir}&nosturtz=1`);

                        // if (this.items.length === 0) {
                        //     window.scrollTo(0,0);
                        // }

                        if (data.docs.length === 0) {
                            this.doneLoading = true;
                        } else {
                            this.items = [...this.items, ...data.docs];
                        }
                    }
                } catch(err) {
                    this.$bvToast.toast('Please try again later.', {
                    title: `Something went wrong`,
                    toaster: 'b-toaster-top-center',
                    variant: 'danger',
                    solid: true
                    });
                    
                    console.log(err.message);
                } 

                this.isLoading = false;
            },
            capitalize(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            },
        },
        computed: {
            hasSearch() {
                return this.search.q && this.search.q.length;
            }
        },
        watch: {
            async sort(value) {
                this.sortDir = value === 'added' ? 'desc' : 'asc';

                this.items = [];
                this.skip = 0;
                this.initialLoad = true;

                await this.doSearch();
            }
        }
    }
</script>

<style scoped>
    .card-body {
        background: #f3f3eb !important;
    }
</style>