import moment from "moment";

export default {
    methods: {
        formatDate(date = '') {
            if (date.length === 0) {
                return '';
            }

            return moment(date).format('MMMM D, YYYY');
        },
        formatDateTime(date = '') {
            if (date.length === 0) {
                return '';
            }

            return moment(date).format('MMMM D, YYYY h:mma');
        },
        showError(msg = 'Please try again later.', title = 'Something went wrong') {
            this.$bvToast.toast(msg, {
                title: `${title}`,
                toaster: 'b-toaster-top-center',
                solid: true,
                variant: 'danger',
                autoHideDelay: 5000,
            });
        },
        showWarning(title, msg) {
            this.$bvToast.toast(msg, {
                title: `${title}`,
                toaster: 'b-toaster-top-center',
                solid: true,
                variant: 'info',
            });
        },
        showToast(title, msg) {
            this.$bvToast.toast(msg, {
                title: `${title}`,
                toaster: 'b-toaster-top-center',
                solid: true,
                variant: 'secondary',
            });
        },
        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
    }
}