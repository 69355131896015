import apiClient from "@/services/apiClient";

export default {
    methods: {
        async createCartItem(email, item) {
            const { data } = await apiClient.post(`${process.env.VUE_APP_ORDERS_API_URL}/cart`, { email: email, item: item });

            return data;
        },
        async getCart(email) {
            const { data } = await apiClient.get(`${process.env.VUE_APP_ORDERS_API_URL}/cart/${email}`);

            return data;
        },
        async deleteCartItem(email, itemId, listingId) {
            const { data } = await apiClient.delete(`${process.env.VUE_APP_ORDERS_API_URL}/cart/${email}/${itemId}/${listingId}`);

            return data;
        },
    }
}