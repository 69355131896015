import apiClient from "@/services/apiClient";

export default {
    methods: {
        async createWant(email, itemId) {
            const { data } = await apiClient.post(`${process.env.VUE_APP_ORDERS_API_URL}/want`, { email: email, itemId: itemId });

            return data;
        },
        async getWants(email) {
            const { data } = await apiClient.get(`${process.env.VUE_APP_ORDERS_API_URL}/want/${email}`);

            return data;
        },
        async deleteWant(email, itemId) {
            const { data } = await apiClient.delete(`${process.env.VUE_APP_ORDERS_API_URL}/want/${email}/${itemId}`);

            return data;
        },
    }
}