<template>
    <div>
        <div v-for="(item, index) in items" :key="index">
            <item :item="item"></item>
        </div>
        
    </div>
</template>

<script>
    import Item from "../components/Item";
    import itemMixin from "../api/item";

    export default {
        name: 'ItemView',    
        components: { Item },
        mixins: [ itemMixin ],
        data() {
            return {
                itemId: null,
                items: [],
            }
        },
        async mounted() {
            if (this.$route.params && this.$route.params.itemId) {
                this.itemId = this.$route.params.itemId;
                this.getItems();
            }
        },  
        methods: {
            async getItems() {
                try {
                    let items = await this.fetchItem(this.itemId);  
                    this.items = items.docs;
                } catch (err) {
                    console.log(err.response.status);
                }
            }
        }
    }
</script>
