<template>
    <div>
        <b-alert :show="errorMsg.length > 0" variant="danger">{{ errorMsg }}</b-alert>
        
        <b-alert :show="errorMsg.length === 0" variant="dark">{{ infoMsg }}</b-alert>

        <div id="card-element"></div>

        <div id="card-errors" role="alert"></div>

        <b-button :disabled="isBusy" @click="chargeCard" variant="danger" class="mt-3">Submit Payment</b-button>
    </div>
</template>

<script>
    import axios from "axios";
    import {loadStripe} from '@stripe/stripe-js';

    export default {
        name: "Stripe",
        props: [ 'amount' ],
        data() {
            return {
                stripeKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
                stripe: null,
                card: null,
                clientSecret: null,
                isBusy: true,
                errorMsg: '',
                infoMsg: 'We never store your credit card information.'
            }
        },
        async created() {
            this.setupStripe();
        },
        methods: {
            async setupStripe() {
                const paymentIntent = await axios.post(`${process.env.VUE_APP_ORDERS_API_URL}/payment/stripe/payment-intent`, { orderTotal: this.amount * 100 });
                this.clientSecret = paymentIntent.data.clientSecret;

                const stripe = await loadStripe(this.stripeKey);
                this.stripe = stripe;

                const elements = stripe.elements();
                this.card = elements.create("card", {
                    style: {
                        base: {
                            fontSize: '16px',
                        }
                    }
                });

                this.card.mount("#card-element");

                this.card.on('change', ({error, complete}) => {
                  if (error) {
                    this.isBusy = true;
                    this.errorMsg = error.message;
                  } else if (complete) {
                      this.isBusy = false;
                      this.errorMsg = '';
                      this.infoMsg = 'Your card is ready to charge.';
                  }
                });
            },
            async chargeCard() {
                this.isBusy = true;

                const resp = await this.stripe.confirmCardPayment(this.clientSecret, {
                    payment_method: {
                        card: this.card,
                    }
                });

                if (resp.error) {
                    this.isBusy = true;
                    this.errorMsg = resp.error.message;
                } else if (resp.paymentIntent.status === 'succeeded') {
                    this.$emit('paymentMade', resp);
                }
            }
        }
    }
</script>

<style scoped>
    #card-element {
        font-size: 2rem !important;
        background: white !important;
        padding: .5rem .75rem !important;
        border: 1px solid #d1d2d8 !important
    }
</style>