import axios from "axios";
import VueApp from "../main";

let instance = axios.create();

instance.interceptors.request.use(async req => {
    let token;

    try {
        token = await VueApp.$auth.getTokenSilently();
    } catch {
        console.log('not logged in');
    }

    if (token && (req.url.includes(process.env.VUE_APP_ORDERS_API_URL) ||
        req.url.includes(process.env.VUE_APP_INVENTORY_API_URL) || 
        req.url.includes(process.env.VUE_APP_TIMECARD_API_URL))) {
                req.headers.authorization = `Bearer ${token}`;
            }
    return req;
        });

export default instance;