import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
// import Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        cart: [],
        wants: [],
        countryCode: null,
        shippingMethod: null,
        address: {},
        loggedIn: false,
    },
    mutations: {
        storeWants(state, wants) {
            state.wants = wants;
        },
        storeCart(state, cart) {
            state.cart = cart;
        },
        addToCart(state, item, quantity = 1) {
            let cartQuantity = quantity;

            state.cart.forEach(cartItem => {
                if (cartItem.itemId === item.itemId) {
                    cartQuantity += cartItem.cartQuantity;
                }
            });

            item.cartQuantity = cartQuantity;

            if (state.cart.indexOf(item) === -1) {
                state.cart.push(item);
            }
        },
        removeOneFromCart(state, item) {
            let newCart = [];

            state.cart.forEach(cartItem => {
                if (cartItem.id === item.id) {
                    cartItem.cartQuantity = cartItem.cartQuantity - 1;
                }

                newCart.push(cartItem);
            });

            state.cart = newCart;
        },
        removeFromCart(state, item) {
            let newCart = [];

            state.cart.forEach(cartItem => {
                if (cartItem.itemId !== item.itemId) {
                    newCart.push(cartItem);
                }
            });

            state.cart = newCart;
        },
        clearCart(state) {
            state.cart = [];
        },
        storeCountryCode(state, code) {
            state.countryCode = code;
        },
        removeCountryCode() {
            this.state.countryCode = null;
        },
        changeShipping(state, option) {
            state.shippingMethod = option;
        },
        storeAddress(state, address) {
            state.address = address;
        },
        login(state) {
            state.loggedIn = true;
        },
        logout(state) {
            state.loggedIn = false;
        }
    },
    actions: {
    },
    modules: {
    },
    plugins: [createPersistedState()],
})
