<template>
    <div>
        <b-row>
            <b-col>
                <b-form-group label="Type" label-for="input-type">
                    <b-form-select :options="typeOptions"
                            id="input-type"
                            v-model="phone.type"
                            required>
                    <template #first>
                        <b-form-select-option :value="null" disabled>Select type</b-form-select-option>
                    </template>        
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group label="Number" label-for="input-number">
                    <b-form-input
                            id="input-number"
                            v-model="phone.number"
                            placeholder="Enter number"
                            required
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                phone: {
                    type: null,
                    number: null,
                },
                typeOptions: [
                    'Mobile',
                    'Home',
                    'Work',
                    'Fax',
                ],
            }
        },
        watch: {
            phone: {
                deep: true,
                handler() {
                    this.$emit('updatePhone', this.phone);
                }
            }
        }
    }
</script>
