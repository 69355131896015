<template>
    <div>
         <b-row class="mb-3">
            <b-col>
                <h4>About Us</h4>
            </b-col>
        </b-row>

        <h5>The Story</h5>
           <b-card class="mb-5">
               <b-card-text>
            <p>Our story begins in 1974 when an 18-year-old blues lover decided to turn his hobby into a business serving similarly-obsessed collectors.</p>
            <p>Armed with $1,000, Craig and his even younger brother, Keith, set off across America in a '65 Ford Galaxie. Over the course of six weeks, they covered a dozen states and 6,000 miles, diving into dusty stacks in already plundered warehouses and stuffing what they bought into the trunk of their car. Despite the suspicion they caused among Texas state troopers and by-the-hour hotel operators, the trip proved to be a shoestring success, leading to hundreds of other trips over the next few decades.</p>
            <p>Today, Records By Mail offers one of the most comprehensive selections of vinyl LPs and 45s available anywhere. Housed in an 8,000 square foot warehouse that currently holds over 2 million records, Craig and his talented, dedicated staff serve music lovers in more than 70 countries.</p>
               </b-card-text>
           </b-card>

        <h5>Videos</h5>
        <b-row class="mb-5">
            <b-col md="6" class="mb-3">
                <b-embed
                    type="iframe"
                    aspect="16by9"
                    src="https://www.youtube.com/embed/RUHdyjXvFk4"
                    allowfullscreen
                ></b-embed>
            </b-col>
            <b-col>
                <b-embed
                    type="iframe"
                    aspect="16by9"
                    src="https://www.youtube.com/embed/OZAQU1x-Zwo"
                    allowfullscreen
                ></b-embed>
            </b-col>
        </b-row>

        <h5>Our Staff</h5>
        <b-card-group deck>
            <b-card
                title="Craig"
                :img-src="imagePath + '/craig.jpg'"
                img-alt="Craig"
                img-top
                class="mb-2">

                <b-card-text>
                    Owner / Founder
                </b-card-text>
            </b-card>

            <b-card
                title="Edward"
                :img-src="imagePath + '/edward.jpg'"
                img-alt="Edward"
                img-top
                class="mb-2">

                <b-card-text>
                    Manager / Buyer
                </b-card-text>
            </b-card>
        </b-card-group>

        <b-card-group deck class="mt-3">
           <b-card
                title="Jamin"
                :img-src="imagePath + '/jamin.jpg'"
                img-alt="Jamin"
                tag="article"
                class="mb-2">

                <b-card-text>
                    Inventory Specialist / Buyer
                </b-card-text>
            </b-card>

            <b-card
                title="Joanna"
                :img-src="imagePath + '/joanna.jpg'"
                img-alt="Joanna"
                class="mb-2">

                <b-card-text>
                    Customer Support
                </b-card-text>
            </b-card>

            <b-card
                title="Jason"
                :img-src="imagePath + '/jason.jpg'"
                img-alt="Jason"
                img-top
                class="mb-2">

                <b-card-text>
                    Inventory Specialist
                </b-card-text>
            </b-card>
        </b-card-group>

        <b-card-group deck class="mt-3">
            <b-card
                title="K.J."
                :img-src="imagePath + '/kj.jpg'"
                img-alt="K.J."
                img-top
                class="mb-2">

                <b-card-text>
                    Multimedia Specialist
                </b-card-text>
            </b-card>

             <b-card
                title="Ken"
                :img-src="imagePath + '/ken.jpg'"
                img-alt="Ken"
                class="mb-2">

                <b-card-text>
                    Warehouse
                </b-card-text>
            </b-card>

             <b-card
                title="Tim"
                :img-src="imagePath + '/tim.jpg'"
                img-alt="Tim"
                img-top
                class="mb-2">

                <b-card-text>
                    Software Developer
                </b-card-text>
            </b-card>
        </b-card-group>

        <b-card-group deck class="mt-3">

        <b-card
                title="Photos Coming Soon"
                class="mb-2">

                <h5>Mark</h5>
                <b-card-text>
                    Shipping
                </b-card-text>

                <h5>Chad</h5>
                <b-card-text>
                    Warehouse
                </b-card-text>
            </b-card>
            
            <b-card
                title="Photos Coming Soon"
                class="mb-2">
             <h5>Scott</h5>
                <b-card-text>
                    Warehouse
                </b-card-text>

                <h5>Gage</h5>
                <b-card-text>
                    Multimedia
                </b-card-text>
            </b-card>
        </b-card-group>
    </div>
</template>

<script>
    export default {
        name: "About",
        computed: {
            imagePath() {
                return process.env.VUE_APP_EMPLOYEE_IMAGES_URL;
            }
        }
    }
</script>

<style scoped>
    .card-body {
        background: #f3f3eb !important;
    }
</style>
