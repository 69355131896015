<template>
    <div>
        <b-navbar toggleable="lg" class="px-0">
    
    <b-navbar-toggle target="filter-toggle-collapse" class="filter-toggle">
        <template #default="{ expanded }">
        <span class="d-inline pr-3">Filters</span>
        <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
        <b-icon v-else icon="chevron-bar-down"></b-icon>
      </template>
    </b-navbar-toggle>

    <b-collapse id="filter-toggle-collapse" is-nav class="p-3">
      <b-navbar-nav>
        <b-nav-form @submit.stop.prevent>

        <b-input-group class="filter-group">
                    <search-filter  title="Genre" 
                                    :values="genres" 
                                    filter="genre" 
                                    :search="search"
                                    :value="search.genre"
                                    @addFilter="addFilter($event)"
                                    @removeFilter="removeFilter($event)">
                    </search-filter>
               
                    <search-filter  title="Size" 
                                    :values="sizes" 
                                    filter="size" 
                                    :search="search"
                                    :value="search.size"
                                    @addFilter="addFilter($event)"
                                    @removeFilter="removeFilter($event)">
                    </search-filter>

                    <search-filter  title="Format" 
                                    :values="durations" 
                                    filter="duration" 
                                    :search="search"
                                    :value="search.duration"
                                    @addFilter="addFilter($event)"
                                    @removeFilter="removeFilter($event)">
                    </search-filter>

                    <search-filter  title="Speed" 
                                    :values="speeds" 
                                    filter="speed" 
                                    :search="search"
                                    :value="search.speed"
                                    @addFilter="addFilter($event)"
                                    @removeFilter="removeFilter($event)">
                    </search-filter>

                    <search-filter  title="Grade" 
                                    :values="grades" 
                                    filter="grade" 
                                    :search="search"
                                    :value="search.grade"
                                    @addFilter="addFilter($event)"
                                    @removeFilter="removeFilter($event)">
                    </search-filter>

                    <search-filter  title="Date Listed" 
                                    :values="recents" 
                                    filter="recent" 
                                    :search="search"
                                    :value="search.recent"
                                    @addFilter="addFilter($event)"
                                    @removeFilter="removeFilter($event)">
                    </search-filter>
                    
                    <search-filter  title="Price Under" 
                                    :values="priceUnders" 
                                    filter="priceUnder" 
                                    :search="search"
                                    :value="search.priceUnder"
                                    @addFilter="addFilter($event)"
                                    @removeFilter="removeFilter($event)">
                    </search-filter>

                    <search-filter  title="Price Over" 
                                    :values="priceOvers" 
                                    filter="priceOver" 
                                    :search="search"
                                    :value="search.priceOver"
                                    @addFilter="addFilter($event)"
                                    @removeFilter="removeFilter($event)">
                    </search-filter>

        </b-input-group>

                <b-input-group class="mt-3">
                    <b-checkbox v-model="search.hasImages" class="mr-3" @change="handleCheckbox">Images</b-checkbox>
                    <b-checkbox v-model="search.hasClips" class="mr-3" @change="handleCheckbox">Soundclips</b-checkbox>
                    <b-checkbox v-model="search.mono" class="mr-3" @change="handleCheckbox">Mono</b-checkbox>
                    <b-checkbox v-model="search.promo" class="mr-3" @change="handleCheckbox">Promo</b-checkbox>
                    <b-checkbox v-model="search.reissue" class="mr-3" @change="handleCheckbox">Reissue</b-checkbox>
                    <b-checkbox v-model="search.isActive" class="mr-3" @change="handleCheckbox">In Stock</b-checkbox>
                </b-input-group>
                
                <b-button @click="clearFilters()" class="mr-3 mt-3" size="sm">Clear</b-button>
        </b-nav-form>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>

        <b-row v-if="activeFilters.length">
            <b-col>
                <ul id="my-custom-tags-list"
                    class="list-unstyled d-inline-flex flex-wrap mb-0">
                    <b-card
                        v-for="active in activeFilters"
                        :key="active.value"
                        tag="li"
                        class="mt-1 mr-1"
                        body-class="py-1 pr-2 text-nowrap filter-container">
                            <strong>{{ filterText(active.filter, active.value) }}</strong>
                            <b-button
                            @click="removeFilter(active.filter)" 
                            variant="link"
                            size="sm">remove</b-button>
                    </b-card>
                </ul>
            </b-col>
        </b-row>
    </div>
</template>
<script> 
import filterMixin from "../assets/filters.js";
import SearchFilter from "./SearchFilter";

export default {
    name: "SearchFilterList",
    mixins: [ filterMixin ],
    components: {
        SearchFilter,
    },
    data() {
        return {
            search: {},
            sort: '',
            sortDir: 'asc',
        }
    },
    created() {
        this.resetFilters();
    },
    methods: {
        async clearFilters() {
            this.resetFilters();
            this.$emit('changeFilter', this.search);
        },
        resetFilters() {
            this.search = {
                q: '',
                genre: '',
                size: '',
                duration: '',
                speed: '',
                grade: '',
                recent: '',
                priceOver: '',
                priceUnder: '',
                hasImages: false,
                hasClips: false,
                mono: false,
                promo: false,
                reissue: false,
                isActive: true,
            }
        },
        async removeFilter(filter) {
            this.$set(this.search, filter, '');
            this.$emit('changeFilter', this.search);
        },
        async addFilter(obj) { 
            this.$set(this.search, obj.filter, obj.value);
            this.$emit('changeFilter', this.search);
        },
        async handleCheckbox() {
            this.$emit('changeFilter', this.search);

            // if (this.buildQuery().length) {
            //     this.items = [];
            //     this.skip = 0;

            //     // await this.doSearch();
            // } else {
            //     this.items = [];
            //     this.skip = 0;
            // }
        },
        filterText(filter, value) {
            let text;
            let values = this[filter + 's'];

            values.forEach(val => {
                if (typeof val === 'object') {
                    if (val.value === value) {
                        text = val.text;
                    }
                } else {
                    if (val === value) {
                        text = val;
                    }
                }
            });

            return text;
        }
    },
    computed: {
        activeFilters() {
                let active = [];

                for (let i in this.search) {
                    if (i !== 'q' && this.search[i].length) {
                        active.push({ filter: i, value: this.search[i] });
                    }
                }

                return active;
            },
    },
    watch: {
        async sort(value) {
                this.$emit('changeSort', value);
        },
    }
}
</script>

<style scoped>
    .filter-container,
    .filter-toggle {
        background: #f3f3eb;
    }

    #filter-toggle-collapse {
        background: #f3f3eb;
        border: 1px solid rgba(75, 75, 47, 0.125);
    }

    @media (max-width: 992px) {
       .filter-group div {
            width: 100%;
        }
    }
    
</style>