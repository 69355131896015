<template>
    <div>
        <div v-if="order.customer && !isLoading">
            <b-row>
                <b-col>
                    <h4>Order {{ orderId }} &mdash; ${{ order.orderTotal }} ({{ orderStatus(order.location, order.shipping.shipments) }})</h4>
                </b-col>

                <b-col class="ml-auto">
                    <h6>{{ formatDate(order.platform.date) }}</h6>
                </b-col>
            </b-row>
            <b-row class="mt-3 mb-5" v-if="order.notes.length && order.notes[0].type === 'customer'">
                <b-col>
                    <h5>Order Note</h5>
                    <b-card>
                        <b-card-text>
                            <em>{{ order.notes[0].text }}</em>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>

            <b-row class="mt-3">
                <b-col sm="12" md="6" class="mb-3">
                    <h5>Address</h5>
                    <b-card>
                        <b-card-text>
                            <p class="mb-0">{{ order.customer.fullName }}</p>
                            <p class="mb-0">{{ order.customer.address.line1 }}</p>
                            <p class="mb-0">{{ order.customer.address.line2 }}</p>
                            <p class="mb-0">{{ cityStateZip(order.customer.address) }}</p>
                            <p class="mb-0">{{ order.customer.address.country }}</p>
                        </b-card-text>
                    </b-card>
                </b-col>

                <b-col>
                    <h5>Shipping</h5>
                    <b-list-group-item>Method: {{ order.shipping.method }}</b-list-group-item>
                    <b-list-group-item>Cost: ${{ order.shipping.cost }}</b-list-group-item>
                    <b-list-group-item v-if="order.shipping.shipments.length > 0">Tracking: {{ order.shipping.shipments[0].tracking }}</b-list-group-item>

                    <div v-if="order.payment.length" class="mt-3">
                        <h5>Payment</h5>
                        <div v-for="(payment, index) in order.payment" :key="index" class="mb-3">
                            <!-- <b-list-group-item v-if="payment.method === 'PayPal'">Type: <b-link @click="openPayPalTransaction(payment.transactionId)">{{ payment.method }}</b-link></b-list-group-item> -->
                            <b-card>
                                <p class="mb-0" v-if="payment.date">Date: {{ formatDateTime(payment.date) }}</p>
                                <p class="mb-0">Type: {{ payment.method }}</p>
                                <p class="mb-0" v-if="payment.amount">Amount: ${{ payment.amount }}</p>
                                <p class="mb-0" v-if="payment.note">Note: <em>{{ payment.note }}</em></p>
                            </b-card>

                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-row class="mt-3">
                <b-col>
                    <h5>Items</h5>
                    <cart-item v-for="(item, index) in order.items" :key="index" :item="item.details" isReadOnly="1"></cart-item>
                </b-col>
            </b-row>
        </div>

        <div v-else-if="isLoading">
            <h6>Loading...</h6>
        </div>

        <div v-else>
            <h6>No order found.</h6>
        </div>
    </div>
</template>

<script>
    import orderApi from "../api/order";
    import customerMixin from "../mixins/customer";
    import CartItem from "../components/CartItem";
    import orderMixin from "../mixins/order";

    export default {
        name: "Order",
        mixins: [ orderApi, customerMixin, orderMixin ],
        components: { CartItem },
        data() {
            return {
                orderId: null,
                order: {},
                isLoading: true,
            }
        },
        mounted() {
            if (this.$route.params && this.$route.params.orderId) {
                this.orderId = this.$route.params.orderId;
            }

            if (this.$route.query.newOrder) {
                this.showToast('Order Confirmed', 'Thanks for your order!');
            }
            
        },
        methods: {
           async getOrder() {
               try {
                   this.isLoading = true;
                   this.order = await this.fetchOrder(this.orderId);
                } catch (err) {
                    console.log(err);
                } finally {
                    this.isLoading = false;
                }
           },
           openPayPalTransaction(transactionId) {
               window.open(`${process.env.VUE_APP_PAYPAL_TXN_URL}/${transactionId}`);
           },
        },
        computed: {
           
        },
        watch: {
            '$auth.user': async function(user) {
                if (user.email && this.$auth.isAuthenticated && this.orderId) {
                    await this.getOrder();
                }
            },
            'orderId': async function() {
                if (this.$auth.isAuthenticated) {
                    await this.getOrder();
                }
            }
        }
    }
</script>

<style scoped>
    .card, .list-group-item {
        background: #f3f3eb !important;
    }
</style>