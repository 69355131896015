export default {
    methods: {
        inStock(item) {
            return item.listing.count > 0 && !item.listing.offsite;
        },
        buildArtist(item) {
            if (item.release.artist.first) {
                return `${item.release.artist.first} ${item.release.artist.last}`;
            }

            return `${item.release.artist.last}`;
        },
        buildTitle(item) {
            if (!item.release.titles) {
                return '';
            }

            return item.release.titles[0];
        },
        buildFullTitle(item) {
            return item.release.titles.join(' / ');
        },
        buildLabelName(item) {
            if (!item.release.label || !item.release.label.name) {
                return '';
            }

            return item.release.label.name.toUpperCase();
        },
        buildLabelNo(item) {
            if (!item.release.label || !item.release.label.catalogNo) {
                return '';
            }

            return item.release.label.catalogNo;
        },
        buildGenre(item) {
            let genre;

            item.release.genres.forEach(g => {
                if (!g.type) {
                    genre = g.name;
                }
            });

            return genre;
        },
        buildStyles(item) {
            return item.release.genres.map(g => {
                if (g.type) {
                    return g.name;
                }
            }).filter(Boolean).join(', ');
        },
        buildMedia(item) {
            return item.release.media.map(m => {
                let media = '';

                if (m.count > 1) {
                    media += + m.count + ' x '
                }

                if (m.duration !== 'Cassette' && m.duration !== 'Cassette Single' && m.duration !== 'CD' && m.duration !== 'CD Single') {
                    media += m.size + '"';
                }

                media += ' ' + m.duration;

                if (m.duration !== 'Cassette' && m.duration !== 'Cassette Single' && m.duration !== 'CD' && m.duration !== 'CD Single') {
                    media += ', ' + m.speed + 'rpm';

                    // if (this.buildMediaAttributes(m).length === 0) {
                    //     media += ')';
                    // }
                }

                if (this.buildMediaAttributes(m).length) {
                    media += ', ' + this.buildMediaAttributes(m);
                }

                return media;
            }).join(', ');
        },
        buildMediaAttributes(media) {
            return media.attributes.join(', ');
        },
        buildPressing(item) {
            return item.release.pressing.map(pressing => {
                return pressing.text;
            }).join(', ');
        },
        buildHype(item) {
            return item.notes.map(n => {
                if (!n.type) {
                    return n.text;
                }
            }).filter(Boolean).join(', ');
        },
        buildCondition(item) {
            return item.listing.condition.map(c => {
                let grade = '';

                let cGrade = c.grade === 'SS' ? 'Still Sealed' : c.grade;

                if (c.subject === 'item' || !c.subject) {
                    grade += 'Grade: ' + cGrade;
                } else {
                    grade += this.capitalize(c.subject) + ' Grade: ' + cGrade;
                }

                if (c.notes.length) {
                    grade += ' (' + c.notes.join(', ') + ')';
                }

                return grade;
            });
        },
        buildListingAttributes(item) {
            return item.listing.attributes.join(', ');
        },
        buildListingAdditions(item) {
            return item.listing.additions.join(', ');
        },
        buildAttributes(item) {
            let attributes = '';

            if (this.buildListingAttributes(item).length) {
                attributes += this.buildListingAttributes(item);
            }

            if (this.buildListingAdditions(item).length) {
                if (attributes.length) {
                    attributes += ', ';
                }

                attributes += this.buildListingAdditions(item);
            }

            if (this.buildHype(item).length) {
                if (attributes.length) {
                    attributes += ', ';
                }

                attributes += this.buildHype(item);
            }

            return attributes;
        }
    }
}