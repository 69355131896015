import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import infiniteScroll from 'vue-infinite-scroll'
import { domain, clientId, audience } from "../auth_config.json";
import { Auth0Plugin } from "./auth";
import utils from "./mixins/utils";

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(infiniteScroll);

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
  }
});

Vue.mixin(utils);

const VueApp = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

export default VueApp;