<template>
    <div class="mr-1">
        <b-form-select :value="value" @change="addFilter($event)">
                    <template #first>
                        <b-form-select-option value="" >{{ title }}</b-form-select-option>
                    </template>
                    <b-select-option v-for="(val, index) in values" :key="index" :value="val.value ? val.value : val">
                        {{ val.text ? val.text : val }}
                    </b-select-option>
            </b-form-select>
    </div>
</template>

<script>
    export default {
        name: "SearchFilter",
        props: [ 'filter', 'values', 'search', 'title', 'value' ],
        methods: {
            async removeFilter() {
                this.$emit('removeFilter', this.filter);
            },
            async addFilter(value) { 
                let val = value.value ? value.value : value;
                let text = value.text ? value.text : value;

                if (val === this.search[this.filter]) {
                    this.removeFilter();
                } else {
                    this.$emit('addFilter', { filter: this.filter, value: value, text: text });
                }
            },
        }
    }
</script>

<style scoped>

</style>