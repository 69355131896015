<template>
    <div>
        <b-row class="mb-3">
            <b-col>
                <h4>Shopping Cart</h4>
            </b-col>
        </b-row>

        <div v-if="cartItems.length">
            <b-row>
                <b-col sm="9">
                    <b-row v-for="(item, index) in cartItems" :key="index">
                        <b-col>
                            <cart-item :item="item"></cart-item>
                        </b-col>
                    </b-row>
                </b-col>
            
            <b-col>
                <b-row>
                    <b-col>
                        <b-form-select v-model="selectedOption" @change="changeShippingMethod">
                            <b-form-select-option v-for="(option, index) in shippingOptions"
                                                :value="option.name"
                                                :key="index">{{ option.name }} - ${{ option.cost.toLocaleString() }}</b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>

                <b-row class="mt-3">
                    <b-col>
                        <b-list-group sm="6">
                            <b-list-group-item>Item Total: ${{ itemTotal.toLocaleString() }}</b-list-group-item>
                            <b-list-group-item v-if="!hasFreeShipping">Shipping: ${{ shippingTotal.toLocaleString() }}</b-list-group-item>
                            <b-list-group-item v-else>Shipping: FREE</b-list-group-item>
                            <b-list-group-item>Cart Total: ${{ cartTotal.toLocaleString() }}</b-list-group-item>
                        </b-list-group>
                    </b-col>
                </b-row>

                 <b-row class="mt-3">
                    <b-col>
                        <b-button v-if="$auth.isAuthenticated" variant="primary" to="/checkout/shipping" :disabled="shippingOptions.length === 0">Checkout</b-button>
                        <b-button v-else variant="primary" @click="login('/checkout/shipping')" :disabled="shippingOptions.length === 0">Checkout</b-button>
                    </b-col>
                </b-row>

            </b-col>
            </b-row>
        </div>

        <b-row v-if="cartItems.length === 0">
            <b-col sm="6">
                <div>No items in your cart.</div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import CartItem from "../components/CartItem";
    import countryApi from "../api/country";
    import orderApi from "../api/order";
    import shippingMixin from "../mixins/shipping";
    import cartMixin from "../mixins/cart";
    import accountMixin from "../mixins/account";
    import itemMixin from "../mixins/item";
    import itemApi from "../api/item";

    export default {
        name: "Cart",
        components: { CartItem },
        mixins: [ countryApi, orderApi, shippingMixin, cartMixin, accountMixin, itemApi, itemMixin ],
        data() {
            return {
                shippingOptions: [],
                selectedOption: null,
            }
        },
        async mounted() {
            if (!this.countryCode) {
                const countryCode = await this.getCountryCode();

                if (countryCode) {
                    this.$store.commit('storeCountryCode', countryCode);
                }
            }
            await this.checkCartStock();
            await this.buildShippingOptions();
            this.changeShippingMethod();

        },
        methods: {
            async checkCartStock() {
                if (this.cartItems.length < 1) return;
                for (let i = 0; i < this.cartItems.length; i++) {
                    const listingId = this.cartItems[i].listing._id;
                    const resp = await this.fetchItem(listingId);
                    const item = resp.docs[0];
                    const qty = item.listing.count;

                    if (qty < 1) {
                        const itemTitle = `${this.buildArtist(item)}: ${this.buildTitle(item)}`;
                        await this.removeFromCart(item);
                        this.showWarning('Item sold! Removing from your cart.', itemTitle);   
                    }
                }
            },
            changeShippingMethod() {
                this.$store.commit('changeShipping', this.selectedOption);
            }
        },
        watch: {
            async cartItems() {
                await this.buildShippingOptions();
                
                if (this.shippingOptions.length === 1) {
                    this.selectedOption = this.shippingOptions[0].value;
                }

                this.changeShippingMethod();
            }
        }
    }
</script>

<style scoped>
    .list-group-item {
        background: #f3f3eb !important;
    }
</style>