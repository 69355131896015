import orderApi from '../api/order';
import cartMixin from '../mixins/cart';

export default {
    mixins: [ orderApi, cartMixin ],
    methods: {
        async buildShippingOptions() {
            try {
                let options = await this.getShippingOptions(this.$store.state.cart);
                this.shippingOptions = [];

                for (let i in options) {
                    if (i !== 'First Class Mail') {
                        if (this.isDomestic && options[i].type === 'domestic') {
                            if (this.hasFreeShipping && i && i === 'Media Mail') {
                                this.shippingOptions.push({name: i, cost: 0 });
                            } else {
                                this.shippingOptions.push({name: i, cost: options[i].cost });
                            }
                        }

                        if (!this.isDomestic && options[i].type !== 'domestic') {
                            this.shippingOptions.push({name: i, cost: options[i].cost });
                        }
                    }
                }

                if (!this.shippingMethod && this.shippingOptions.length > 0) {
                    this.selectedOption = this.shippingOptions[0].name;
                } else {
                    this.selectedOption = this.shippingMethod;
                }
            } catch (err) {
                this.showError('Could not fetch shipping options.');
            }
        },
    }
}