import apiClient from "@/services/apiClient";

export default {
    methods: {
        async getCustomerByEmail(email) {
            const { data } = await apiClient.get(`${process.env.VUE_APP_ORDERS_API_URL}/account?email=${email}`);

            return data;
        },
        async createCustomer(customer) {
            const { data } = await apiClient.post(`${process.env.VUE_APP_ORDERS_API_URL}/account`, customer);

            return data;
        },
        async updateCustomer(payload) {
            const { data } = await apiClient.put(`${process.env.VUE_APP_ORDERS_API_URL}/account`, payload);

            return data;
        },
        async createAddress(address) {
            const { data } = await apiClient.post(`${process.env.VUE_APP_ORDERS_API_URL}/account/address`, { address: address });

            return data;
        },
        async deleteAddress(addressId) {
            const { data } = await apiClient.delete(`${process.env.VUE_APP_ORDERS_API_URL}/account/address/${addressId}`);

            return data;
        },
        async createPhone(phone) {
            const { data } = await apiClient.post(`${process.env.VUE_APP_ORDERS_API_URL}/account/phone`, { phone: phone });

            return data;
        },
        async deletePhone(number) {
            const { data } = await apiClient.delete(`${process.env.VUE_APP_ORDERS_API_URL}/account/phone/${number}`);

            return data;
        },
        async changeAccountPassword(email) {
            const payload = {
                client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
                email: email,
                connection: process.env.VUE_APP_AUTH0_DB
            }

            const resp = await apiClient.post(`${process.env.VUE_APP_AUTH0_URL}/dbconnections/change_password`, payload);

            return resp;
        },
        async fetchAccountOrders() {
            const { data } = await apiClient.get(`${process.env.VUE_APP_ORDERS_API_URL}/account-orders`);

            return data;
        }
    }
}