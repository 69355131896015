import orderApi from '../api/order';

export default {
    mixins: [ orderApi ],
    methods: {
        async buildOrder(paymentType, transactionId, orderNotes) {
            const cust = await this.getCustomerByEmail(this.$auth.user.email);
            const options = await this.getShippingOptions(this.$store.state.cart);
            let cost = this.hasFreeShipping ? 0 : options[this.$store.state.shippingMethod].cost || 0;
            
            let order = {
                customer: {
                    email: this.$auth.user.email,
                    fullName: cust.fullName,
                    address: this.$store.state.address,
                    customerId: cust.customerId,
                },
                shipping: {
                    method: this.$store.state.shippingMethod,
                    cost: cost,
                },
                platform: {
                    site: process.env.VUE_APP_WEB_PLATFORM
                }
            }

            if (paymentType && transactionId) {
                let date = Date.now();
                let total = cost + this.itemTotal;

                order.payment = { method: paymentType, transactionId: transactionId, date: date, amount: total };
            }

            if (orderNotes && orderNotes.length) {
                order.notes = { type: 'customer', text: orderNotes };
            }
            
            return order;
        },
        async buildOrderItem(item)  {
            return {
                itemId: item.itemId,
                listingId: item.listing._id,
                quantity: item.cartQuantity,
                platform: {
                    site: process.env.VUE_APP_WEB_PLATFORM,
                    quantity: item.cartQuantity,
                    price: item.listing.price,
                }
            }
        },
        orderStatus(location, shipments = []) {
            if (!location) {
                return 'New';
            }

            if (shipments.length > 0) {
                return 'Shipped';
            }

            const statuses = {
                'Warehouse': 'Processing',
                'Office': 'On Hold',
                'Shipping': 'Shipping',
                'Cancelled': 'Cancelled',
            }

            return statuses[location];
        }
    },
    computed: {
        isDomestic() {
            return this.countryCode === 'US';
        },
        hasFreeShipping() {
            return this.$store.state.shippingMethod === 'Media Mail' && this.isDomestic && this.itemTotal > parseInt(process.env.VUE_APP_FREE_SHIPPING_THRESHOLD);
        },
        countryCode() {
            return this.$store.state.countryCode;
        },
        itemTotal() {
            let total = 0;

            this.$store.state.cart.forEach(item => {
                if (!isNaN(item.listing.price)) {
                    total += parseFloat(item.listing.price * item.cartQuantity);
                }
            });

            return total;
        },
    }
}