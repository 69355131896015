import apiClient from "@/services/apiClient";

export default {
    methods: {
        async getCountryCode() {
            try {
                const { data } = await apiClient.get(`${process.env.VUE_APP_IP_REGISTRY_URL}?key=${process.env.VUE_APP_IP_REGISTRY_KEY}&fields=location.country.code`);

                return data.location.country.code;
            } catch {
                console.log('could not fetch country from IP');
            }
        }
    }
}