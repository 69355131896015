<template>
    <div>
        <b-row class="mb-3">
            <b-col>
                <h4>Wants</h4>
            </b-col>
        </b-row>
    
        <!-- <div v-for="(want, index) in wants" :key="index">
            <item :item="want"></item>
        </div> -->
        <list :items="wants" wantView="1" @reload="loadWants"></list>

        <div class="text-center" v-if="isLoading">
            <b-spinner label="Spinning"></b-spinner>
        </div>

        <div v-if="wants && wants.length === 0 && !isLoading">
            No wants found.
        </div>
    </div>
</template>

<script>
import itemApi from "@/api/item";
import List from "../components/List";

 export default {
        name: "Wants",
        mixins: [ itemApi ],
        components: { List },
        data() {
            return {
                wants: [],
                isLoading: false,
            }
        },
        mounted() {
            this.loadWants();
        },
        methods: {
            async loadWants() {
                try {
                    this.isLoading = true;
                    
                    const wants = await this.fetchItems(this.$store.state.wants);
                    
                    this.wants = wants.docs;
                } catch (err) {
                    this.showError('Could not load your wants.');
                } finally {
                    this.isLoading = false;
                }
            }
        }
 }
</script>