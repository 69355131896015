<template>
    <div>
        <b-card no-body class="overflow-hidden">
            <b-row no-gutters v-if="imgView">
                <b-col class="p-3">
                    <b-button size="sm" @click="imgView = false">Back to item</b-button>
                    <b-row class="mt-3">
                        <b-col sm="10" class="mb-3">
                            <b-card-img :src="originalImgUrl(imgIndex)" alt="Image" class="rounded-0" style="max-width: 600px"></b-card-img>
                        </b-col>

                        <b-col>
                            <div v-for="(img, index) in item.listing.images" :key="index" class="mb-3">
                                <b-link @click="imgIndex = index">
                                    <b-img thumbnail :src="buildImgUrl(index, true)" width="100" height="100" :class="{ 'selected': index === imgIndex }"></b-img>
                                </b-link>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row no-gutters v-else>
                <b-col lg="6" class="p-3">
                    <div>
                        <b-link v-if="buildImgUrl(imgIndex).length" @click="imgView = true" class="fullsize-image">
                            <b-card-img :src="buildImgUrl(imgIndex)" alt="Image" class="rounded-0 img-fluid"></b-card-img>
                        </b-link>
                        <div v-else class="blank-image-container text-center">
                            <img src="../assets/rbm_logo.png" width="213" />
                        </div>
                    </div>
                    <b-row class="mt-3 justify-content-start">
                        <b-col class='flex-grow-0 thumbCol mt-3' v-for="(img, index) in item.listing.images" :key="index">
                            <b-link @click="imgIndex = index">
                                <b-img thumbnail :src="buildImgUrl(index, true)" width="100" height="100" :class="{ 'selected': index === imgIndex }"></b-img>
                            </b-link>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col lg="6">
                    <b-card-body>
                        <b-card-text>
                            <small>Item #{{ item.itemId }} &middot; {{ buildGenre(item) }}</small>

                            <h4 class="mb-0">{{ buildArtist(item) }}</h4>
                            <h5 class="mb-0">{{ buildFullTitle(item) }}</h5>
                            <p>{{ buildLabelName(item) }} {{ buildLabelNo(item) }}</p>
                            <div v-if="hasLabelDetails">
                                <p class="mb-0"><small v-if="hasDeadwax">Deadwax: {{ item.release.label.deadwax }}</small></p>
                                <p class="mb-0"><small v-if="hasAddress">Address: {{ item.release.label.address }}</small></p>
                                <p class="mb-0"><small v-if="hasLocale">Locale: {{ item.release.label.locale }}</small></p>
                            </div>

                            <!-- <p class="mb-0">Genre: <span>{{ buildGenre(item) }}</span>
                                <span v-if="buildStyles(item).length">, {{ buildStyles(item) }}</span>
                            </p> -->
                            <p class="mb-0" v-if="buildStyles(item).length">Style: {{ buildStyles(item) }}</p>
                            <p>Format: {{ buildMedia(item) }}</p>

                            <div v-if="buildPressing(item).length" class="my-3">
                                <!-- <p class="my-0" v-for="(pressing, index) in buildPressing(item)" :key="index">
                                    {{ capitalize(pressing.type) }}: {{ pressing.text }}
                                </p> -->
                                 <p class="my-0">
                                    Pressing: {{ buildPressing(item) }}
                                </p>
                            </div>

                            <div v-if="inStock(item)">
                                <p v-for="(grade, index) in buildCondition(item)" :key="index">
                                    {{ grade }}
                                </p>

                                <em>{{ buildAttributes(item) }}</em>
                            </div>

                            <h5 class="text-success mt-3" v-if="inStock(item)">${{ item.listing.price.toLocaleString() }}</h5>
                            <h5 v-else class="mt-3"><small><em>(out of stock)</em></small></h5>

                            <div class="mt-5">
                                <b-button v-if="!inCart(item) && inStock(item)" variant="danger" @click="doAddToCart(item)">Add to cart</b-button>
                                <b-button v-else-if="inCart(item)" variant="danger" @click="removeFromCart(item)">Remove from cart</b-button>

                                <span v-if="$auth.isAuthenticated">
                                    <b-button v-if="!inWants(item.itemId)" :class="{ 'ml-3': inStock(item) }" @click="doAddToWants(item.itemId)">Add to wants</b-button>
                                    <b-button v-else :class="{ 'ml-3': inStock(item) }" @click="doRemoveFromWants(item.itemId)">Remove from wants</b-button>
                                </span>
                                <span v-else>
                                    <b-button v-if="!inStock(item)" @click="login()">Login to add to wants</b-button>
                                </span>
                            </div>

                            <div class="mt-5" v-for="(clip, index) in item.listing.soundclips" :key="index">
                                <audio controls>
                                    <source :src="clipUrl(clip)" type="audio/mpeg">
                                Audio tag is not supported in this browser.
                                </audio>
                            </div>
                        </b-card-text>
                    </b-card-body>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
    import itemMixin from "../mixins/item";
    import cartMixin from "../mixins/cart";
    import wantMixin from "../mixins/want";
    import accountMixin from "../mixins/account";
    import wantApi from "../api/want";

    export default {
        name: "Item",
        props: [ 'item' ],
        mixins: [ itemMixin, cartMixin, wantMixin, accountMixin, wantApi ],
        data() {
            return {
                imgIndex: 0,
                imgView: false,
            }
        },
        methods: {
            doAddToCart(item) {
                this.addToCart(item);
                // this.showToast('Item was added to your cart');
                // this.$bvModal.show('cartModal');
            },
            async doAddToWants(itemId) {
                try {
                    if (!this.$auth.isAuthenticated) {
                        await this.login();
                    }

                    if (this.$auth.isAuthenticated) {
                        const resp = await this.createWant(this.$auth.user.email, itemId);
                        this.$store.commit('storeWants', resp.itemIds);
                    }
                } catch (err) {
                    if (err.message && err.message === 'Login required') {
                        await this.login();

                        await this.doAddToWants(itemId);
                    } else {
                        this.showError(err);
                    }
                }
            },
            async doRemoveFromWants(itemId) {
                try {
                    if (!this.$auth.isAuthenticated) {
                        await this.login();
                    }

                    if (this.$auth.isAuthenticated) {
                        const resp = await this.deleteWant(this.$auth.user.email, itemId);
                        this.$store.commit('storeWants', resp.itemIds);
                    }
                } catch (err) {
                    if (err.message && err.message === 'Login required') {
                        await this.login();

                        await this.doRemoveFromWants(itemId);
                    } else {
                        this.showError(err);
                    }
                }
            },
            buildImgUrl(imgIndex, isThumbnail = false) {
                if (!this.item.listing.images || !this.item.listing.images[imgIndex]) {
                    return ``;
                }
                
                let width = isThumbnail ? process.env.VUE_APP_THUMBNAIL_WIDTH : process.env.VUE_APP_IMAGE_WIDTH;
                
                const imageRequest = JSON.stringify({
                    bucket: 'record-photos',
                    key: this.item.listing.images[imgIndex].url,
                    edits: { resize: { width: width } },
                });

                return `${process.env.VUE_APP_ITEM_IMAGE_PATH}/${btoa(imageRequest)}`;
            },
            originalImgUrl(imgIndex) {
                if (this.item.listing.images[imgIndex]) {
                    return `${process.env.VUE_APP_S3_IMAGES_URL}/${this.item.listing.images[imgIndex].url}`;
                }
            },
            clipUrl(clip) {
                return `${process.env.VUE_APP_S3_CLIPS_URL}/${clip.url}`;
            },
            openImage(index) {
                window.open(this.originalImgUrl(index), 'imageViewer');
            }
        },
        computed: {
            hasLabelDetails() {
                return this.item.label && (this.item.label.deadwax || this.item.label.address || this.item.label.locale);
            },
            hasDeadwax() {
                return this.item.label && this.item.label.deadwax;
            },
            hasAddress() {
                return this.item.label && this.item.label.address;
            },
            hasLocale() {
                return this.item.label && this.item.label.locale;
            },
        }
    }
</script>

<style lang="scss">
    .img-thumbnail {
        padding: 0.25rem !important;
    }

    .img-thumbnail.selected {
        border: 2px solid #4C4C43;
    }

    a.fullsize-image {
        cursor: zoom-in;
    }

    .blank-image-container {
        padding: 72px 0;
        opacity: 0.5;
    }

    .thumbCol {
        min-width: 100px;
    }
</style>