export default {
    methods: {
        inWants(itemId) {
            for (let i = 0; i < this.$store.state.wants.length; i++) {
                if (this.$store.state.wants[i] === itemId) {
                    return true;
                }
            }

            return false;
        },
        removeFromWants(itemId) {
            this.$store.commit('removeFromWants', itemId);
        },
        addToWants(itemId) {
            this.$store.commit('addToWants', itemId);
        },
    }
}