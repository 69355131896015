export default {
    methods: {
        cityStateZip(address) {
            let csz = '';

            csz += address.city || '';

            if (address.city && address.city.length) {
                csz += ', ';
            }

            csz += address.state || '';

            csz += ' ' + (address.zip || '');

            return csz.trim();
        },
        countryName(code) {
            for (let i = 0; i < this.countries.length; i++) {
                if (this.countries[i].code === code) {
                    return this.countries[i].name;
                }
            }
        }
    }
}