<template>
  <div id="app">
      <b-card title="Card Title" no-body>
        <b-card-header header-tag="nav" class="sticky-top nav-menu">
          
<b-navbar toggleable="lg">
    <b-navbar-brand to="/">
      <img src="./assets/rbm_logo.png" style="width: 100px;" class="mr-3">
      <h5 class="mt-3 pt-1 d-none d-xl-inline-block">RECORDS BY MAIL</h5>
    </b-navbar-brand>

    <b-navbar-toggle target="navbar-toggle-collapse">
    </b-navbar-toggle>

    <b-collapse id="navbar-toggle-collapse" is-nav v-model="navbarOpen">
      <b-navbar-nav class="ml-auto mt-3">
        <b-nav-form @submit.stop.prevent class="ml-lg-auto mb-3 mr-lg-5">
          <b-input-group>
            <b-input-group-prepend class="browse-button-container">
                      <b-button to="/browse">Browse</b-button>
            </b-input-group-prepend>
            <b-input id="searchText" class="search-input" v-model="query" :placeholder="searchPlaceholder" v-on:keyup.enter="doSearch()" type="search" ref="searchInput"></b-input>
            <b-dropdown variant="link" class="search-type-dropdown">
                  <b-dropdown-item @click.prevent="changeSearchType()">All</b-dropdown-item>
                  <b-dropdown-item @click.prevent="changeSearchType('artist')">Artist</b-dropdown-item>
                  <b-dropdown-item @click.prevent="changeSearchType('title')">Title</b-dropdown-item>
                  <b-dropdown-item @click.prevent="changeSearchType('label')">Label</b-dropdown-item>
                </b-dropdown>            
            <b-input-group-append>
              <b-button @click="doSearch()" class="search-button">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-nav-form>
        
        <b-nav-item to="/cart">Cart <b-badge class="ml-1">{{ cartCount }}</b-badge></b-nav-item>
        <b-nav-item v-if="$auth.isAuthenticated" to="/wants">Wants <b-badge class="ml-1">{{ wantCount }}</b-badge></b-nav-item>
        <b-nav-item v-if="$auth.isAuthenticated" to="/account">Account</b-nav-item>
        <b-nav-item v-else @click="doLogin()">Account</b-nav-item>

        <b-nav-item-dropdown
            text="Help"
            right>
            <b-dropdown-item><b-link to="/help/shipping">Shipping &amp; Returns</b-link></b-dropdown-item>
            <b-dropdown-item><b-link to="/help/grading">Record Grading</b-link></b-dropdown-item>
            <b-dropdown-item><b-link to="/help/contact">Contact Us</b-link></b-dropdown-item>
            <b-dropdown-item><b-link to="/help/about">About Us</b-link></b-dropdown-item>
            <b-dropdown-item><b-link to="/help/privacy">Privacy Notice</b-link></b-dropdown-item>
            <b-dropdown-item><b-link to="/help/terms">Terms Of Use</b-link></b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item><b-link to="/help/collection">Sell Your Collection</b-link></b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
        </b-card-header>
        <!-- </div> -->
    <b-card-body class="mx-3">
      <router-view :key="$route.fullPath" />
    </b-card-body>
          </b-card>
  </div>
</template>

<script>
import wantApi from "./api/want";
import cartApi from "./api/cart";
import accountMixin from "./mixins/account";

  export default {
    name: 'App',
    mixins: [ wantApi, cartApi, accountMixin ],
    data() {
      return {
        query: '',
        allowedPaths: [
          '/checkout/shipping',
          '/',
          '/browse',
          '/cart',
        ],
        navbarOpen: null,
        type: '',
      }
    },
    created() {
      if (this.$route.params.query && this.$route.name === 'Search') {
        this.query = this.$route.params.query;
      }
    },
    methods: {
      doLogin() {
        if (this.allowedPaths.indexOf(this.$route.path) !== -1) {
          this.login(this.$route.path);
        } else {
          this.login('/account');
        }
      },
      doSearch() {
          if (this.query.length && (this.$route.params.query !== this.query || this.$route.query.type !== this.type)) {
            let query = {};
            
            if (this.type && this.type.length) {
              query.type = this.type;
            }

            this.$router.push({ path: `/search/${this.query}`, query });
          }
      },
      changeSearchType(type = '') {
        this.type = type;
      },
    },
    computed: {
      cartCount() {
        return this.$store.state.cart.length;
      },
      wantCount() {
        return this.$store.state.wants.length;
      },
      searchType() {
        return this.type?.charAt(0).toUpperCase() + this.type?.slice(1);
      },
      searchPlaceholder() {
        if (this.type === 'artist') {
          return `Search by artist`;
        }

        if (this.type === 'title') {
          return `Search by title`;
        }

        if (this.type === 'label') {
          return `Search by label`;
        }
    
        return `Search by artist, title, and/or label`;
      }
    },
    watch: {
      '$auth.user.email': async function() {
        if (!this.$store.state.loggedIn) {
          await this.handleLogin();
        } 
      },
      '$route': function() {
        if (this.navbarOpen) {
          this.navbarOpen = false;
        }
      }
    }
}
</script>

<style>
  
</style>

<style lang="scss">
@import "./styles/_variables.scss";

#app {  
  font-family: $base-font, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-menu {
  background: $body-bg !important;
}

.card-title {
  color: $dark-green;
}

.dropdown-item.active, .dropdown-item:active {
  background: #e9ecef !important;
}

h4 {
  color: $header-font-color;
}

.navbar-light .navbar-toggler {
  color: $dark-green !important;
}

.navbar-light .navbar-nav .nav-link {
    color: $dark-green !important;
}

.navbar-toggler {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  border: 1px solid rgba(75, 75, 47, 0.125) !important;
}

.search-input {
  min-width: 275px !important;
  border-right: 0 !important;
}

.search-type-dropdown {
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  background: #f8f8fe;
}

.search-type-dropdown button {
  padding-bottom: 0.25rem;
}

@media (max-width: 600px) {
  .search-input {
   min-width: 150px !important;
  }

  .browse-button-container {
    width: 100% !important;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}

@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/bootstrap-vue/src/index.scss';


</style>
