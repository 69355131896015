<template>
    <div>     
        <b-card v-for="(address, index) in component.address" :key="index" class="mb-3">
            <b-card-text>
                <p class="mb-0">{{ customer.fullName }}</p>
                <p class="mb-0">{{ address.line1 }}</p>
                <p class="mb-0">{{ address.line2 }}</p>
                <p class="mb-0">{{ cityStateZip(address) }}</p>
                <p class="mb-0">{{ countryName(address.country) }}</p>
            </b-card-text>
            <b-button v-if="isSelecting && index !== selected" size="sm" @click="selectAddress(index)">Ship to this address</b-button>
            <b-button v-if="!isSelecting" size="sm" @click="removeAddress(address)">Remove address</b-button>
            
            <template #header v-if="isSelecting && index === selected">
                <strong><em>Ship To:</em></strong>
            </template>
        </b-card>

        <b-button variant="danger" size="sm" @click="newAddress()" class="mb-3">Create new address</b-button>

        <b-modal id="addressModal"
                 size="lg"
                 scrollable
                 hide-footer
                 hide-header
                 button-size="sm"
                 centered>
            <b-row>
                <b-col>
                    <address-form @updateAddress="updateAddress"></address-form>

                    <b-button :disabled="!canSave" size="sm" @click="saveAddress()" class="mt-3">Save new address</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import objectMixin from "../mixins/object";
    import customerMixin from "../mixins/customer";
    import accountApi from "../api/account";
    import AddressForm from "./AddressForm";
    import countryList from "../assets/countryList";

    export default {
        name: "AccountAddress",
        props: [ 'customer', 'isSelecting' ],
        components: { AddressForm },
        mixins: [ objectMixin, accountApi, customerMixin, countryList ],
        data() {
            return {
                address: {
                    line1: null,
                    line2: null,
                    city: null,
                    state: null,
                    zip: null,
                    country: null,
                },
                selected: 0,
            }
        },
        async mounted() {
            this.clone(this.customer);

            this.clearAddress();
        },
        methods: {
            clearAddress() {
                this.address = {
                    line1: null,
                    line2: null,
                    city: null,
                    state: null,
                    zip: null,
                    country: null,
                };
            },
            selectAddress(index) {
                this.selected = index;
                this.$emit('addressSelected', this.component.address[index]);
            },
            updateAddress(address) {
                this.address = address;
            },
            async removeAddress(address) {
                const canRemove = await this.$bvModal.msgBoxConfirm('Are you sure you want to remove this address?', {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    footerClass: 'p-2 address-remove',
                    hideHeaderClose: false,
                    centered: true
                });

                try {
                    if (canRemove) {
                        await this.deleteAddress(address._id);
                        this.$store.commit('changeShipping', null);
                        this.$emit('updateAccount');
                    }
                } catch (err) {
                    this.showError('Could not remove address.');
                }
            },
            async saveAddress() {
                try {
                    await this.createAddress(this.address);
                    
                    this.component.address.push(this.address);
                    this.$emit('updateAccount');
                    this.selectAddress(this.selected);
                    this.$store.commit('storeCountryCode', this.address.country);
                    this.$store.commit('changeShipping', null);

                    this.$bvModal.hide('addressModal');
                } catch (err) {
                    this.showError('Could not create address.');
                }
            },
            newAddress() {
                this.clearAddress();

                this.$bvModal.show('addressModal');
            },
        },
        computed: {            
            hasRequired() {
                return this.shipping.name.length > 0
                    && this.shipping.address.length > 0
                    && this.shipping.zip.length > 0
                    && this.shipping.country.length > 0
                    && this.shipping.method.length > 0;
            },
            canSave() {
                return this.address.line1 && this.address.line1.length > 0
                    && this.address.zip && this.address.zip.length > 0
                    && this.address.country && this.address.country.length > 0;
            }
        },
        watch: {
            customer(cust) {
                this.clone(cust);
                this.$emit('addressSelected', this.component.address[this.selected]);
            },
            '$auth.user.email': function(email) {
                if (email.length) {
                    this.component.email = email;
                }
            }
        }
    }
</script>

<style scoped>
    .card-header {
        background: #fff;

    }

    .card-body {
        background: #f3f3eb;
    }
</style>

<style>

    footer.address-remove {
        display: block !important;
        text-align: right;
    }
</style>