import axios from "axios";

export default {
    methods: {
        async fetchItem(itemId) {
            const { data } = await axios.get(`${process.env.VUE_APP_INVENTORY_API_URL}?q=${itemId}`);

            return data;
        },
        async fetchItems(itemIds) {
            const itemIdsStr = itemIds.join(',');

            const { data } = await axios.get(`${process.env.VUE_APP_INVENTORY_API_URL}?itemIds=${itemIdsStr}`);

            return data;
        },
    }
}