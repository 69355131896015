<template>
    <div>
        <h4 class="mb-3">Payment</h4>

        <b-overlay :show="isCreating" opacity="0.5">
            <b-row>
                <b-col v-if="cartItems.length > 0" sm="12" md="6" class="mb-3">
                    <h5>Select payment method</h5>

                    <b-card>
                        <b-form-group :disabled="shippingOptions.length === 0" class="mb-0">
                            <div class="paypal-button"></div>
                            <b-form-radio-group
                                    class="payment-options"
                                    v-model="selected"
                                    :options="options"
                                    name="plain-inline"
                                    plain
                            ></b-form-radio-group>
                        </b-form-group>

                        <b-form-input class="mt-3" v-if="selected === 'Pay Later'" v-model="orderNotes" placeholder="Enter order instructions"></b-form-input>

                        <stripe class="mt-3" v-if="selected === 'Credit Card'" @paymentMade="doOrderCreateCC('Credit Card', $event)" :amount="cartTotal"></stripe>
                        <b-button class="mt-3" v-if="selected === 'Pay Later'" variant="danger" @click="doOrderCreate()">Create order</b-button>
                    </b-card>
                </b-col>

                <b-col md="3" v-if="cartItems.length > 0">
                    <h5>Totals</h5>
                    <b-list-group>
                        <b-list-group-item>Item Total: ${{ itemTotal }}</b-list-group-item>
                        <b-list-group-item>Shipping: ${{ shippingTotal }}</b-list-group-item>
                        <b-list-group-item>Cart Total: ${{ cartTotal }}</b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>

<script>
    import Stripe from "./Stripe";
    import accountApi from "../api/account";
    import orderMixin from "../mixins/order";
    import shippingMixin from "../mixins/shipping";
    import orderApi from "../api/order";
    import paymentMixin from "../mixins/payment";
    import cartMixin from "../mixins/cart";
    import itemMixin from "../mixins/item";
    import itemApi from "../api/item";


    export default {
        name: "Payment",
        components: { Stripe, },
        mixins: [ accountApi, orderMixin, shippingMixin, orderApi, paymentMixin, cartMixin, itemMixin, itemApi ],
        data() {
            return {
                isWindowActive: true, // Flag to track window activity
                selected: '',
                options: [
                    { text: 'Credit Card', value: 'Credit Card' },
                    { text: 'Pay Later', value: 'Pay Later' }
                ],
                orderNotes: null,
                isCreating: false,
                shippingOptions: [],
            }
        },
        async mounted() {
            window.addEventListener("focus", this.handleWindowFocus);
            window.addEventListener("blur", this.handleWindowBlur);
            await this.buildShippingOptions();  
            await this.checkCartStock();
            this.initPayPal();
        },
        beforeDestroy() {
            // Remove event listeners when component is destroyed
            window.removeEventListener("focus", this.handleWindowFocus);
            window.removeEventListener("blur", this.handleWindowBlur);
        },
        computed: {
            paypalItems() {
                return this.cartItems.map(item => {
                    let name = `${this.buildArtist(item)}: ${this.buildTitle(item)} ${this.buildMedia(item)}`.substring(0, 127);

                    return { name: name, unit_amount: { currency_code: "USD", value: item.listing.price }, quantity: 1 }
                });
            },

        },
        methods: {
            async handleWindowFocus() {
                // Update window activity flag
                this.isWindowActive = true;

                // Check cart stock when window becomes active
                this.checkCartStock();
            },
            handleWindowBlur() {
                // Update window activity flag
                this.isWindowActive = false;
            },
            async checkCartStock() {
                if (this.isWindowActive && this.$store.state.cart.length > 0) {
                    for (let i = 0; i < this.cartItems.length; i++) {
                        const listingId = this.cartItems[i].listing._id;
                        const resp = await this.fetchItem(listingId);
                        const item = resp.docs[0];
                        const qty = item.listing.count;

                        if (qty < 1) {
                            const itemTitle = `${this.buildArtist(item)}: ${this.buildTitle(item)}`;
                            await this.removeFromCart(item);
                            this.showWarning('Item sold! Removing from your cart.', itemTitle);
                        }
                    }
                }   
            },
            initPayPal() {  
                window.paypal.Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                description: 'Records By Mail Order',
                                amount: {
                                    currency_code: "USD",
                                    value: this.cartTotal,
                                    breakdown: {
                                        item_total: {
                                            currency_code: "USD",
                                            value: this.itemTotal,
                                        },
                                        shipping: {
                                            currency_code: "USD",
                                            value: this.shippingTotal,
                                        }
                                    }
                                },
                                items: this.paypalItems,
                            }]
                        });
                    },
                    onApprove: async (data, actions) => {
                        this.isCreating = true;
                        const order = await actions.order.capture();
                        await this.doOrderCreate('PayPal', order.id);
                        console.log(order);
                    },
                    onError: err => {
                        this.showError('Something went wrong with your payment');
                        console.log(err);
                    },
                    style: {
                        layout:  'horizontal',
                        color:   'gold',
                        shape:   'pill',
                        label:   'paypal',
                        tagline: false,
                    }
                }).render('.paypal-button');
            },
            async doOrderCreate(paymentType, transactionId) {
                try {
                    this.isCreating = true;

                    const orderData = await this.buildOrder(paymentType, transactionId, this.orderNotes);
                    const order = await this.createOrder(orderData);
                    
                    await this.addItemsToOrder(order);
                    await this.removeItemsFromCart();

                    try {
                        await this.sendEmailConfirmation(order.orderId);
                    } catch {
                        console.log('Could not send email.');
                    }
                    
                    this.isCreating = false;

                    // redirect to order page
                    this.$router.push({ path: `/order/${order.orderId}`, query: { 'newOrder': 1 }});
                } catch (err) {
                    this.showError('Could not create the order.');
                } finally {
                    this.isCreating = false;
                }
            },
            async doOrderCreateCC(paymentType, transaction) {
                try {
                    this.isCreating = true;
                    let transactionId = transaction.paymentIntent.id;

                    const orderData = await this.buildOrder(paymentType, transactionId, this.orderNotes);
                    
                    if (transaction.paymentMethodDetails && transaction.paymentMethodDetails.card && transaction.paymentMethodDetails.card.country && transaction.paymentMethodDetails.card.country === 'CN') {
                        orderData.location = "Review";
                    }

                    const order = await this.createOrder(orderData);
                    
                    await this.addItemsToOrder(order);
                    await this.removeItemsFromCart();

                    try {
                        await this.sendEmailConfirmation(order.orderId);
                    } catch {
                        console.log('Could not send email.');
                    }
                    
                    this.isCreating = false;

                    // redirect to order page
                    this.$router.push({ path: `/order/${order.orderId}`, query: { 'newOrder': 1 }});
                } catch (err) {
                    this.showError('Could not create the order.');
                } finally {
                    this.isCreating = false;
                }
            },
            async addItemsToOrder(order) {
                for (let i = 0; i < this.$store.state.cart.length; i++) {
                    try {
                        let orderItem = await this.buildOrderItem(this.$store.state.cart[i]);
                        await this.createOrderItem(order.orderId, orderItem);
                    } catch {
                        this.showError('Error adding item(s) to the order.')
                    }
                }
            },
            async removeItemsFromCart() {
                try {
                    for (let i = 0; i < this.$store.state.cart.length; i++) {
                        await this.removeFromCart(this.$store.state.cart[i]);
                    }

                    // clear cart
                    this.$store.commit('storeCart', []);
                } catch {
                    this.showError('Some items are still in your cart.');
                }
            }
        },
    }
</script>

<style scoped>
    .list-group-item {
        background: #f3f3eb !important;
    }

    .card-body {
        background: #f3f3eb !important;
    }
    .paypal-button {
        display: inline-block;
        width: 200px;
        padding-top: 6px;
    }

    .payment-options {
        display: inline-block;
        vertical-align: top;
        position: relative;
        top: 12px;
        left: 20px;
    }
</style>