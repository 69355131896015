<template>
    <div>     
        <div class="mb-3" v-if="component.phone && component.phone.length > 0">
            <div v-for="(phone, index) in component.phone" :key="index" class="mb-3">
            
                <b-input-group :prepend="phone.type + ' number'" v-if="phone.number.length">
                    <b-input disabled :value="phone.number"></b-input>
                    <b-input-group-append>
                        <b-button size="sm" @click="removePhone(phone)">Remove</b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>
        <b-button variant="danger" size="sm" @click="newPhone()" class="mb-3">Create new phone number</b-button>

        <b-modal id="phoneModal"
                 size="sm"
                 scrollable
                 hide-footer
                 hide-header
                 button-size="sm"
                 centered>
            <b-row>
                <b-col>
                    <phone-form @updatePhone="updatePhone"></phone-form>

                    <b-button :disabled="!canSave" size="sm" @click="savePhone()" class="mt-3">Save new phone number</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import objectMixin from "../mixins/object";
    import customerMixin from "../mixins/customer";
    import accountApi from "../api/account";
    import PhoneForm from "./PhoneForm";

    export default {
        name: "AccountPhone",
        props: [ 'customer', 'isSelecting' ],
        components: { PhoneForm },
        mixins: [ objectMixin, accountApi, customerMixin ],
        data() {
            return {
                phone: {
                    type: null,
                    number: null,
                },
            }
        },
        async mounted() {
            this.clone(this.customer);

            this.clearPhone();
        },
        methods: {
            clearPhone() {
                this.phone = {
                    type: null,
                    number: null,
                }
            },
            updatePhone(phone) {
                console.log(phone);
                this.phone = phone;
            },
            async removePhone(phone) {
                const canRemove = await this.$bvModal.msgBoxConfirm('Are you sure you want to remove this number?', {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    footerClass: 'p-2 phone-remove',
                    hideHeaderClose: false,
                    centered: true
                });

                try {
                    if (canRemove) {
                        await this.deletePhone(phone.number);
                        this.$emit('updateAccount');
                    }
                } catch (err) {
                    this.showError('Could not remove phone.');
                }
            },
            async savePhone() {
                try {
                    console.log(`creating ${this.phone}`);

                    await this.createPhone(this.phone);
                    
                    this.component.phone.push(this.phone);
                    this.$emit('updateAccount');
                    this.$bvModal.hide('phoneModal');
                } catch (err) {
                    this.showError('Could not create phone.');
                }
            },
            newPhone() {
                this.clearPhone();

                this.$bvModal.show('phoneModal');
            },
        },
        computed: {            
            // hasRequired() {
            //     return this.shipping.name.length > 0
            //         && this.shipping.address.length > 0
            //         && this.shipping.zip.length > 0
            //         && this.shipping.country.length > 0
            //         && this.shipping.method.length > 0;
            // },
            canSave() {
                return this.phone.type && this.phone.type.length > 0
                    && this.phone.number && this.phone.number.length > 0;
            }
        },
        watch: {
            customer(cust) {
                this.clone(cust);
            },
            '$auth.user.email': function(email) {
                if (email.length) {
                    this.component.email = email;
                }
            }
        }
    }
</script>

<style scoped>
    .card-header {
        background: #fff;

    }

    .card-body {
        background: #f3f3eb;
    }
</style>

<style>

    footer.phone-remove {
        display: block !important;
        text-align: right;
    }
</style>